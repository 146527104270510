<template>
	<div class="dash-board-container">
		<!-- 顶部菜单栏 -->
		<top-nav-menu activeIndex="dashboard" @navTags="navTags"></top-nav-menu>
		
		<!-- 数据 -->
		<div v-if="role_id != 9">
			<board-data></board-data>
		</div>
		


	</div>
</template>

<script>
	import topNavMenu from '@/components/TopNavMenu.vue';
	import boardData from '@/components/boardData/boardData.vue'; // 首页数据
	
	import tagsNav from '@/components/common/tags-nav.vue';

	// 引入vuex 模块
	import {
		mapMutations
	} from 'vuex';
	import {
		mapState
	} from 'vuex';

	export default {
		components: {
			topNavMenu,
			boardData,
			tagsNav
		},
		data() {
			return {
				activeName: 'first',
				role_id: '',
				

			}
		},
		created() {
			this.role_id = sessionStorage.getItem('role_id')
		},
		computed: {
			...mapState(['routerNav']) // 缓存路由导航
		},
		methods: {
			...mapMutations(['addRouter', 'editNavActive']),
			navTags(path) {
				// console.log('123',path)
				this.addRouter(path)
				this.editNavActive(this.routerNav.length - 1)
			},
			
			 handleClick(tab, event) {
				// console.log(tab, event);
		     }
			


		}
	}
</script>

<style lang="less" scoped>
	.dash-board-container {}
	
	.sign-fixed {
		position: fixed;
		right: 0;
		bottom: 100px;
		width: 80px;
		height: 80px;
		padding: 10px 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: var(--baseColor);
		color: #fff;
		font-size: 26px;
		cursor: pointer;
		box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
		border-radius: 6px 0 0 6px;
		span {
			margin-top: 6px;
			font-size: 16px;
		}
	}
	
	
	
</style>
