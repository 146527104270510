import Vue from 'vue'
import VueRouter from 'vue-router'

// vuex 
import store from '../store/index.js';


import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import ResourceQuery from '../views/ResourceQuery.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login' // 重定向到登录页
  },
  {
    path: '/login',
	name: 'login',
	meta: {
		title: '登录',
		keepAlive: false,
	},
    component: Login // 登录页
  },
  {
    path: '/dashboard',
	name: 'dashboard',
	meta: {
		title: '首页',
		keepAlive: false,															
	},
    component: () => import('../views/Dashboard.vue') // 首页
  },
  {
    path: '/resourcequery',
	name: 'resourcequery',
	meta: {
		title: '已分配资源'
	},
    component: () => import('../views/ResourceQuery.vue') // 已分配资源
  },
  {
    path: '/customerDetail',
  	name: 'customerDetail',
	meta: {
		title: '客户详情'
	},
    component: () => import('../views/customerM/CustomerDetail.vue') // 客户详情
  },
  {
    path: '/customerappointment',
  	name: 'customerappointment',
	meta: {
		title: '客户预约'
	},
    component: () => import('../views/customerM/CustomerAppointment.vue') // 客户预约
  },
  {
    path: '/salestoallocate',
  	name: 'salestoallocate',
	meta: {
		title: '销售待分配'
	},
    component: () => import('../views/customerM/SalesToAllocate.vue') // 销售待分配
  },
  {
    path: '/salesCustomer',
  	name: 'salesCustomer',
	meta: {
		title: '销售客户'
	},
    component: () => import('../views/customerM/SalesCustomer.vue') // 销售客户
  },
  {
    path: '/serviceDivide',
  	name: 'serviceDivide',
	meta: {
		title: '服务待分配'
	},
    component: () => import('../views/customerM/ServiceDivide.vue') // 服务待分配
  },
  {
    path: '/serviceCustomer',
  	name: 'serviceCustomer',
	meta: {
		title: '服务客户'
	},
    component: () => import('../views/customerM/ServiceCustomer.vue') // 服务客户
  },
  {
    path: '/serviceEndCustomer',
  	name: 'serviceEndCustomer',
	meta: {
		title: '服务完结客户'
	},
    component: () => import('../views/customerM/ServiceEndCustomer.vue') // 服务完结客户
  },
  {
    path: '/talented',
  	name: 'talented',
	meta: {
		title: '佳丽才俊'
	},
    component: () => import('../views/customerM/Talented.vue') // 佳丽才俊
  },
  {
    path: '/dealCustomer',
  	name: 'dealCustomer',
	meta: {
		title: '成交客户'
	},
    component: () => import('../views/customerM/DealCustomer.vue') // 成交客户
  },
  {
    path: '/seaCustomer',
  	name: 'seaCustomer',
	meta: {
		title: '公海客户'
	},
    component: () => import('../views/customerM/SeaCustomer.vue') // 公海客户
  },
  {
    path: '/seatMonitor',
  	name: 'seatMonitor',
	meta: {
		title: '坐席监听'
	},
    component: () => import('../views/operateM/seatMonitor.vue') // 坐席监听
  },
  {
    path: '/callLog',
  	name: 'callLog',
	meta: {
		title: '通话记录'
	},
    component: () => import('../views/operateM/callLog.vue') // 通话记录
  },
  {
    path: '/messageRecord',
  	name: 'messageRecord',
	meta: {
		title: '短信记录'
	},
    component: () => import('../views/operateM/messageRecord.vue') // 短信记录
  },
  {
    path: '/signRecord',
  	name: 'signRecord',
	meta: {
		title: '签到记录'
	},
    component: () => import('../views/operateM/signRecord.vue') // 签到记录
  },
  {
    path: '/orderHandle',
  	name: 'orderHandle',
	meta: {
		title: '订单处理'
	},
    component: () => import('../views/orderM/orderHandle.vue') // 订单处理
  },
  {
    path: '/refundHandle',
  	name: 'refundHandle',
	meta: {
		title: '退款处理'
	},
    component: () => import('../views/orderM/refundHandle.vue') // 退款处理
  },
  {
    path: '/revokeRecord',
  	name: 'revokeRecord',
	meta: {
		title: '撤销记录'
	},
    component: () => import('../views/orderM/revokeRecord.vue') // 撤销记录
  },
  {
    path: '/callData',
  	name: 'callData',
	meta: {
		title: '通话数据'
	},
    component: () => import('../views/dataCount/callData.vue') // 通话数据
  },
  {
    path: '/operateData',
  	name: 'operateData',
	meta: {
		title: '运营数据'
	},
    component: () => import('../views/dataCount/operateData.vue') // 运营数据
  },
  {
    path: '/accountMan',
  	name: 'accountMan',
	meta: {
		title: '账号管理'
	},
    component: () => import('../views/systemSet/accountMan.vue') // 账号管理
  },
  {
    path: '/organizationMan',
  	name: 'organizationMan',
	meta: {
		title: '组织管理'
	},
    component: () => import('../views/systemSet/organizationMan.vue') // 组织管理
  },
  {
    path: '/messageCenter',
  	name: 'messageCenter',
	meta: {
		title: '消息中心'
	},
    component: () => import('../views/systemSet/MessageCenter.vue') // 消息中心
  },
  {
    path: '/virtualLibrary',
  	name: 'virtualLibrary',
  	meta: {
  		title: '我的虚拟库'
  	},
    component: () => import('../views/customerM/VirtualLibrary.vue') // 我的虚拟库
  },
  {
    path: '/contractMan',
  	name: 'contractMan',
  	meta: {
  		title: '合同管理'
  	},
    component: () => import('../views/contractM/contractMan.vue') // 合同管理
  },
  {
    path: '/depositAudit',
  	name: 'depositAudit',
  	meta: {
  		title: '定金审核'
  	},
    component: () => import('../views/depositAudit.vue') // 定金审核
  },
  {
    path: '/refundReview',
  	name: 'refundReview',
  	meta: {
  		title: '退款审核'
  	},
    component: () => import('../views/refundReview.vue') // 退款审核
  },
  {
    path: '/revocation',
  	name: 'revocation',
  	meta: {
  		title: '撤销'
  	},
    component: () => import('../views/revocation.vue') // 退款审核
  },
  {
    path: '/marryreturn',
  	name: 'marryreturn',
  	meta: {
  		title: '撤销'
  	},
    component: () => import('../views/marryreturn.vue') // 婚返
  }
  
  
]

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


// 前置导航守卫。 
// 功能：控制访问权限，没有登录，不允许访问除登录外的其他页面。
router.beforeEach((to, from, next) => {
	console.log(to, '路由刷新了')
	
	// // 记录历史路由
	// setRouterHistory(to)
	
	// to 将要访问的路径
	// from 代表从哪个路径跳转而来
	// next 是一个函数，表示放行
	console.log(to,'to',from,'from');
	if(to.path === '/login') next();
	// 获取token，如果有token,也直接放行
	const tokenStr = window.sessionStorage.getItem('token');
	//  console.log('token', tokenStr);
	if(!tokenStr) return next('/login');
	
	// // 记录历史路由
	setRouterHistory(to)
	
	next();
	
})


// 记录路由历史
const setRouterHistory = (to) => {
	// console.log(to, '设置路由')
	// 要登录状态下
	if(to.title == '登录' || to.title == '首页') {
		return
	}
	
	let obj = {
		title: to.meta.title,
		path: to.path,
		// path: to.fullPath,
	}
	let routerHistory = store.state.app.routerHistory;
	
	routerHistory.push(obj);
    //map去重，避免重复记录重复路由
	let mapArr = () => {
		let map = new Map();
		for (let item of routerHistory) {
			if (!map.has(item.path)) {
				map.set(item.path, item);
			}
		}
		return [...map.values()];
	}
	let newArr = mapArr();
	store.commit('setRouterHistory', newArr)
}




export default router
