let genderList = [{
	label: '不限',
	value: ''
}, {
	label: '男',
	value: 1
}, {
	label: '女',
	value: 2
}]

// 学历
let educationList = [{
	name: '高中以下',
	id: 1,
}, {
	name: '大专',
	id: 2,
}, {
	name: '本科',
	id: 3,
}, {
	name: '硕士',
	id: 4,
}, {
	name: '博士',
	id: 5,
}]


// 婚况
let marriageList = [{
	name: '未婚',
	id: 0
}, {
	name: '丧偶',
	id: 10
}, {
	name: '离异',
	id: 20
}]

// 房
let houseList = [{
	name: '否',
	id: 0
}, {
	name: '是',
	id: 10
}]

// 车
let carList = [{
	name: '否',
	id: 0
}, {
	name: '是',
	id: 10
}]


let customerType = [{
	label: '0类：新分',
	value: 0
},{
	label: '1类：未接听',
	value: 1
},{
	label: '2类：接听未深沟',
	value: 2
},{
	label: '3类：意向会员未确定到店时间',
	value: 3
},{
	label: '4类：已确定到店时间',
	value: 4
},{
	label: '5类：已到店',
	value: 5
},{
	label: '6类：毁单',
	value: 6
},{
	label: '7类：已付定金',
	value: 7
},{
	label: '8类：已签单',
	value: 8
}]

let serviceType = [{
	label: '不限',
	value: ''
},{
	label: '0类：新分未交接',
	value: 0
},{
	label: '1类：已交接(三天持续跟进)',
	value: 1
},{
	label: '2类：已交接未推荐(三天持续跟进)',
	value: 2
},{
	label: '3类：推荐待安排(三天持续跟进)',
	value: 3
},{
	label: '4类：深入交往中(三天持续跟进)',
	value: 4
},{
	label: '5类：恋爱会员(十天持续跟进)',
	value: 5
},{
	label: '6类：过期关单会员',
	value: 6
},{
	label: '7类：失联会员',
	value: 7
},{
	label: '8类：结婚会员',
	value: 8
}]



// 收入
let annual_income = [{
	name: '0-10',
	id: 1,
}, {
	name: '10-20',
	id: 2,
}, {
	name: '20-30',
	id: 3,
}, {
	name: '30-40',
	id: 4,
}]

// 年龄
let ageList = [{
	name: '0-10',
	id: 1,
}, {
	name: '10-20',
	id: 2,
}, {
	name: '20-30',
	id: 3,
}, {
	name: '30-40',
	id: 4,
}, {
	name: '40-70',
	id: 5,
}]

// 身高
let heightList = [{
	name: '120-150',
	id: 1,
}, {
	name: '150-160',
	id: 2,
}, {
	name: '160-170',
	id: 3,
}, {
	name: '170-180',
	id: 4,
}, {
	name: '180-220',
	id: 5,
}]

// 资源列表
let sourceType = [{
	name: '新分',
	id: 1,
}, {
	name: '捞取',
	id: 2,
}, {
	name: '流传',
	id: 3,
}, {
	name: '外带资源',
	id: 4,
}]

let contractCityList = [
	{
		name: '北京',
		id: 9
	}, {
		name: '惠州',
		id: 13
	}, {
		name: '南宁',
		id: 12
	}, {
		name: '深圳',
		id: 8
	}, {
		name: '武汉',
		id: 11
	},{
		name: '珠海',
		id: 3
	},{
		name: '爱思曼',
		id: 1
	}]


// 导出
module.exports = {
	genderList: genderList,
	educationList: educationList,
	marriageList: marriageList,
	customerType,
	annual_income,
	ageList,
	heightList,
	serviceType,
	carList,
	houseList,
	sourceType,
	contractCityList
}
