import Vue from 'vue'
import Vuex from 'vuex'

import app from './app.js'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  token: window.sessionStorage.getItem('token') || '',
	  user_id: window.sessionStorage.getItem('user_id') || '',
	  role_id: window.sessionStorage.getItem('role_id') || '', // 等级id
	  // routerHistory: window.sessionStorage.getItem('routerHistory') || '[]', // 历史路由
  },
  getters: {
	
	  
  },
  mutations: {
	  
	// 缓存token
	setToken(state, token) {
		state.token = token
	},
	setUserId(state, user_id) {
		state.user_id = user_id
	},
	setRoleId(state, role_id) {
		state.role_id = role_id
	},
	// 持久化token
	saveTokenStorage(state) {
		window.sessionStorage.setItem('token', state.token)
		window.sessionStorage.setItem('user_id', state.user_id)
		window.sessionStorage.setItem('role_id', state.role_id)
	},
	// 移除token
	removeToken(state) {
		state.token = ''
		state.user_id = ''
		state.role_id = ''
	},
	
	  
  },
  actions: {
  },
  modules: {
	  app: app
  }
})
