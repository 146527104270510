import storage from '../store/storage.js'
const session = "sessionStorage"
export default {
	state: {
		routerHistory: storage.getValue('routerHistory') || [{
			title: "首页",
			path: "/dashboard"
		}],
		nowRouter: storage.getValue('nowRouter') || '/dashboard',
	},
	getters: {
		routerHistory: state => state.routerHistory,
	},
	mutations: {
		// 设置路由历史
		setRouterHistory(state, routerHistory) {
			state.routerHistory = routerHistory;
			storage.setValue('routerHistory', routerHistory);
		},
		// 清除路由历史
		cleanRouterHistory(state) {
			state.routerHistory = [];
			storage.cleanValue('routerHistory');
		},
		// 设置当前路由
		setNowRouter(state, nowRouter) {
			state.nowRouter = nowRouter;
			storage.setValue('nowRouter', nowRouter);
		},
		// 清除当前路由
		cleanNowRouter(state) {
			state.nowRouter = undefined;
			storage.cleanValue('nowRouter');
		},
	},
	actions: {},
}