<template>
	<div class="sales-container">

		<div class="tabs-container">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="销售数据" name="1">
					<!-- 数据 -->
					<sales-data></sales-data>
					
				</el-tab-pane>
				<!-- <el-tab-pane label="服务数据" name="2">服务数据</el-tab-pane> -->
			</el-tabs>
		</div>


	</div>
</template>

<script>
	import salesData from '../../components/boardData/salesData.vue';
	export default {
		components: {
			salesData
		},
		data() {
			return {
				activeName: '1',



			}
		},
		methods: {
			handleClick(tab, event) {
				// //console.log(tab, event);
			}



		}
	}
</script>

<style lang="less" scoped>
	.tabs-container {
	}

	:deep .el-tabs__header {
		background-color: #fff;
		position: sticky;
		top: 114px;
		z-index: 99;
		background-color: #fff;
	}
	
	:deep .el-tabs__header .el-tabs__nav-wrap {
		padding: 0 30px !important;
	}

	:deep .el-tabs__item {
		height: 48px;
		line-height: 48px;
		font-size: 16px;
	}

	:deep .el-tabs__content {
		padding: 0 30px;
	}
	
	
</style>
