<template>
	<!-- 销售数据 -->
	<div class="container">
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>数据简报</span>
			</div>
			<!-- 筛选 -->
			<div class="screen-container">

				<div class="screen-item">
					<span class="title">组织</span>
					<el-cascader clearable  filterable :props="{ checkStrictly: true }" v-model="organSelectList"  style="width: 400px;" :options="OrganizaList"
						@change="handleChange"></el-cascader>
				</div>
				
				<div class="screen-item">
					<span class="title">日期</span>
					<el-date-picker style="width: 300px;" v-model="dist_time" type="daterange" unlink-panels
						@change="changeTime" value-format="yyyy-MM-dd" range-separator="-" start-placeholder="开始日期"
						end-placeholder="结束日期">
					</el-date-picker>
				</div>

				<!-- 按钮 -->
				<div class="screen-item">
					<el-button @click="reset">重置</el-button>
					<el-button type="primary" @click="conditionQuery">查询</el-button>
				</div>

			</div>


			<!-- 列表 -->
			<div class="data-list">
				<el-row :gutter="24">
					<el-col :span="24">
						<el-card shadow="always">
							<div class="title">汇总</div>
							<div class="options-wrap">
								<div class="item">
									<span>到店数量</span>
									<div>{{ boardData.come_shop_count || 0 }}</div>
								</div>
								<div class="item" @click="gotoList">
									<span>签单数</span>
									<div>{{ boardData.sign_count || 0 }}</div>
								</div>
								<div class="item" @click="gotoList">
									<span>签单金额(元)</span>
									<div>{{ boardData.sign_price || 0 }}</div>
								</div>
								<div class="item" @click="gotoding">
									<span>定金数</span>
									<div>{{ boardData.deposit_count || 0 }}</div>
								</div>
								<div class="item" @click="gotoding">
									<span>定金金额(元)</span>
									<div>{{ boardData.deposit_price || 0 }}</div>
								</div>
								<div class="item">
									<span>总金额(元)</span>
									<div>{{ boardData.sum_price || 0 }}</div>
								</div>
							</div>

						</el-card>
					</el-col>
					<!-- <el-col :span="6">
						<el-card shadow="always">
							<div class="title">资源情况</div>
							<div class="options-wrap">
								<div class="item">
									<span>新分资源 (人)</span>
									<div>680</div>
								</div>
								<div class="item">
									<span>总库存(人)</span>
									<div>2000</div>
								</div>
							</div>
						</el-card>
					</el-col>
					<el-col :span="6">
						<el-card shadow="always">
							<div class="title">目标业绩</div>
							<div class="options-wrap">
								<div class="item">
									<span>总目标</span>
									<div>0万元</div>
								</div>
								<div class="item">
									<span>完成度</span>
									<div>0%</div>
								</div>
							</div>
						</el-card>
					</el-col>
					<el-col :span="6">
						<el-card shadow="always">
							<div class="title">定金金额</div>
							<div class="options-wrap">
								<div class="item">
									<span>金额(万元)</span>
									<div>2.29</div>
								</div>
								<div class="item">
									<span>实收金额(元)</span>
									<div>5000</div>
								</div>
								<div class="item">
									<span>单数(笔)</span>
									<div>15</div>
								</div>
							</div>
						</el-card>
					</el-col> -->
				</el-row>
			</div>


		</el-card>


	</div>
</template>

<script>
	export default {
		data() {
			return {
				
				dist_time: [],
				
				boardData: {},
				
				OrganizaList: [], // 组织
				organSelectList: [],
				
				
				region_id: '',
				shop_id: '',
				department_id: '',
				customer_user_id: '',
				beginTime: '',
				endTime: '',
				

			}
		},
		created() {
			this.getData()
			this.getOrganizationList()
			
		},
		methods: {
			
			
			gotoding() {
				this.$router.push({
					name: 'orderHandle',
					query: {
						activeName: '2'
					}
				})
			},
			gotoList() {
				
				this.$router.push({
					name: 'orderHandle',
				})
			},
			
			// 分配时间选择
			changeTime(e) {
				if (e == null) {
					this.dist_time = [];
					this.beginTime = '';
					this.endTime = '';
				} else {
					this.beginTime = e[0];
					this.endTime = e[1];
				}
			},
			
			// 获取组织
			getOrganizationList() {
			
				this.$axios.get('/manage/Customer/get_shop_tree', {
					params: {
						token: this.$store.state.token,
					}
				}).then(res => {
					//console.log('组织', res)
					if (res.code == 1) {
						this.OrganizaList = res.data.shopCategory;
					}
				})
			},
			
			getData() {
				
				this.$axios.get('/manage/Customer/data_report', {
					params: {
						region_id: this.region_id,
						shop_id: this.shop_id,
						department_id: this.department_id,
						customer_user_id: this.customer_user_id,
						beginTime: this.beginTime,
						endTime: this.endTime
					}
				}).then(res => {
					if(res.code == 1) {
						
						this.boardData = res.data
						
					} else {
						this.$message.error(res.msg)
					}
					
					
				});
			},
			
			// 筛选组织选择
			handleChange(e) {
				
				if(e.length === 1) {
					this.region_id = e[e.length - 1];
					this.shop_id = ''
					this.department_id = ''
					this.customer_user_id = ''
				}
				if(e.length === 2) {
					this.shop_id = e[e.length - 1];
					this.region_id = ''
					this.department_id = ''
					this.customer_user_id = ''
				}
				if(e.length === 3) {
					this.department_id = e[e.length - 1];
					this.region_id = ''
					this.shop_id = ''
					this.customer_user_id = ''
				}
				if(e.length === 4) {
					this.customer_user_id = e[e.length - 1];
					this.region_id = ''
					this.shop_id = ''
					this.department_id = ''
				}
				
			},
			
			// 重置
			reset() {
				this.organSelectList = []
				this.dist_time = []
				this.beginTime = ''
				this.endTime = ''
				this.region_id = ''
				this.shop_id = ''
				this.department_id = ''
				this.customer_user_id = ''
			},
			// 条件查询
			conditionQuery() {
				this.getData()
			},
			
			
			

		}
	}
</script>

<style lang="less" scoped>
	.screen-container {
		display: flex;
		align-items: center;

		.screen-item {
			position: relative;
			margin-right: 20px;

			.title {
				padding: 0 15px;
				font-size: 14px;
			}

			:deep .el-select {
				width: 80px;
			}
		}
	}


	.data-list {
		margin: 20px 0;

		:deep .el-card__body {
			height: 200px;
		}

		.title {
			padding-bottom: 30px;
			font-size: 15px;
			color: #333;
		}

		.options-wrap {
			display: flex;
			justify-content: space-around;
			align-items: center;
			padding-bottom: 20px;
			border-bottom: 1px solid #ddd;

			.item {
				// margin-right: 40px;
				cursor: pointer;
				span {
					font-size: 13px;
					color: #AFB0AF;
				}
				div {
					margin-top: 20px;
					font-size: 24px;
					font-weight: bold;
					color: #333;
					text-align: center;
				}
			}

		}
	}
</style>
