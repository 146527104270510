<template>
	<div class="resource-container">
		<!-- 顶部菜单栏 -->
		<top-nav-menu activeIndex="resourcequery"></top-nav-menu>

		<div class="res-content">

			<!-- 筛选 -->
			<div class="screen-container">
				<div class="screen-item">
					<span class="title">组织</span>
					<el-cascader clearable filterable :props="{ checkStrictly: true }" v-model="organSelectList"
						style="width: 400px;" :options="OrganizaList" @change="handleChange"></el-cascader>
				</div>
				<div class="screen-item">
					<span class="title">类型</span>
					<el-select style="width: 100px;" v-model="is_allocation" placeholder="不限">
						<el-option v-for="item in allocation_list" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="screen-item">
					<span class="title">uid/手机号/昵称</span>
					<el-input clearable class="kefu-input" style="width: 300px;" v-model="keyword"
						placeholder="请输入uid/手机号/昵称">
					</el-input>
				</div>
				<!-- <div class="screen-item">
					<span class="title">手机号</span>
					<el-input clearable class="kefu-input" placeholder="不限"></el-input>
				</div> -->
				<!-- <div class="screen-item">
					<span class="title">分配时间</span>
					<el-date-picker style="width: 250px;" v-model="date_time" type="daterange" unlink-panels
						range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
						:picker-options="pickerOptions">
					</el-date-picker>
				</div> -->
				<div class="screen-item">
					<span class="title">性别</span>
					<el-select v-model="gender" placeholder="不限">
						<el-option v-for="item in genderList" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="screen-item">
					<span class="title">销售类别</span>
					<el-select style="width: 200px;" v-model="type" placeholder="不限">
						<el-option v-for="item in customerType" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="screen-item">
					<span class="title">服务类别</span>
					<el-select style="width: 200px;" v-model="service_type" placeholder="不限">
						<el-option v-for="item in serviceType" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<!-- 按钮 -->
				<div class="screen-item">
					<el-button @click="reset">重置</el-button>
					<el-button type="primary" @click="conditionQuery">查询</el-button>
				</div>

			</div>


			<!-- 表格 -->
			<div class="res-table">

				<el-button style="margin-bottom: 10px;" type="primary" @click="isShowAdd = true">新增用户</el-button>
				<div style="padding-bottom: 20px;">
					<el-upload class="upload-demo" ref="upload" :action="$importFile" :on-success="handleSuccess"
						:on-remove="handleRemove" :file-list="fileList" name="iFile"
						:data="{ token: this.$store.state.token }" :headers="{ token: this.$store.state.token }"
						:auto-upload="false">
						<el-button slot="trigger" size="small" type="primary">选取文件</el-button>
						<el-button style="margin-left: 10px;" size="small" type="success"
							@click="submitUpload">确定导入</el-button>
						<div slot="tip" class="el-upload__tip">只能上传xls文件</div>
					</el-upload>
				</div>


				<el-table border :data="tableData" stripe style="width: 100%">
					<el-table-column prop="user_id" label="UID" width="120">
					</el-table-column>
					<el-table-column label="头像" width="80">
						<template slot-scope="scope">
							<el-avatar shape="square" :src="scope.row.avatarUrl"></el-avatar>
						</template>
					</el-table-column>
					<el-table-column prop="nickName" label="用户昵称" width="180">
					</el-table-column>
					<el-table-column prop="real_name" label="真实姓名" width="140">
				</el-table-column>
					<el-table-column prop="native_place" label="当前位置">
						<template slot-scope="scope">
							<span v-if="scope.row.customer_id != 0 && scope.row.matchmaker_id == 0">销售仓库</span>
							<span v-if="scope.row.customer_id == 0 && scope.row.matchmaker_id != 0">红娘仓库</span>
						</template>
					</el-table-column>
					<el-table-column label="类型">
						<template slot-scope="scope">
							<span v-if="scope.row.is_allocation == 0">未分配</span>
							<span v-if="scope.row.is_allocation == 10">已分配</span>
						</template>
					</el-table-column>
					<el-table-column prop="gender" label="性别" width="80">
					</el-table-column>
					<el-table-column prop="age" label="年龄" width="80">
					</el-table-column>
					<el-table-column prop="location_place" label="工作生活地">
					</el-table-column>
					<el-table-column label="学历">
						<template slot-scope="scope">
							<span v-if="scope.row.education == 0">未知</span>
							<span v-else>{{ educationList[scope.row.education - 1].name }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="annual_income" label="年收入(万)">
					</el-table-column>
					<el-table-column label="销售红娘">
						<template slot-scope="scope">
							<span v-if="scope.row.customer">{{ scope.row.customer.real_name }}</span>
						</template>
					</el-table-column>
					<el-table-column label="服务红娘">
						<template slot-scope="scope">
							<span v-if="scope.row.matchmaker">{{ scope.row.matchmaker.real_name }}</span>
						</template>
					</el-table-column>
					<el-table-column fixed="right" label="操作" width="80">
						<template slot-scope="scope">
							<el-button type="text" size="medium" v-if="role_id != 9"
								@click="gotoDetail(scope.row.user_id)">查看详情</el-button>
						</template>
					</el-table-column>
				</el-table>

				<!-- 分页 -->
				<div class="page-container">
					<el-pagination background @current-change="handleCurrentChange" :current-page="currentPage"
						@size-change="handleSizeChange" @prev-click="prevSize" @next-click="nextSize"
						:page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="limit"
						layout="total, sizes, prev, pager, next, jumper" :total="tableTotal">
					</el-pagination>
				</div>

			</div>
		</div>




		<!-- 新增用户 -->
		<div class="add-user">
			<el-dialog title="新增用户" :visible.sync="isShowAdd" width="35%">

				<el-form ref="userRef" :rules="formRules" :model="formUser" label-width="120px">
					<el-form-item label="选择门店" required>
						<el-cascader style="width: 300px;" v-model="organSelectList3" :options="OrganizaList3"
							@change="handleChange3"></el-cascader>
					</el-form-item>
					<el-form-item label="头像" required>
						<el-upload ref="uploadCardRefs" :action="$uploadImageUrl" list-type="picture-card" name="iFile"
							:headers="{ token: this.$store.state.token }" :on-success="uploadSuccess" :limit="1">
							<i class="el-icon-plus"></i>
						</el-upload>
					</el-form-item>
					<el-form-item label="昵称"  prop="nickName">
						<el-input clearable v-model="formUser.nickName" placeholder="请输入昵称"></el-input>
					</el-form-item>
					<el-form-item label="真实姓名"  prop="real_name">
						<el-input clearable v-model="formUser.real_name" placeholder="请输入真实姓名"></el-input>
					</el-form-item>
					<el-form-item label="性别" required prop="gender">
						<el-radio-group v-model="formUser.gender">
							<el-radio label="1">男</el-radio>
							<el-radio label="2">女</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="手机号码"  prop="mobile">
						<el-input clearable v-model="formUser.mobile" placeholder="请输入手机号码"></el-input>
					</el-form-item>
					<el-form-item label="年龄" required prop="age">
						<el-input-number v-model="formUser.age" controls-position="right" :min="18"></el-input-number>
					</el-form-item>
					<el-form-item label="婚姻状况" required prop="marital_status">
						<el-select v-model="formUser.marital_status" placeholder="请选择婚姻状况">
							<el-option v-for="item in marriageList" :key="item.id" :label="item.name"
								:value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="籍贯" required>
						<el-select v-model="selectNativeProvice" @change="proviceChange(1, $event)" placeholder="请选择省份">
							<el-option v-for="item in provice" :key="item.id" :label="item.name"
								:value="item.id"></el-option>
						</el-select>
						<el-select @change="cityChange(1, $event)" v-model="selectNativeCity" placeholder="请选择市">
							<el-option v-for="item in cityList" :key="item.id" :label="item.name"
								:value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="所在地" required>
						<el-select v-model="selectLocationProvice" @change="proviceChange(2, $event)" placeholder="请选择省份">
							<el-option v-for="item in provice" :key="item.id" :label="item.name"
								:value="item.id"></el-option>
						</el-select>
						<el-select @change="cityChange(2, $event)" v-model="selectLocationCity" placeholder="请选择市">
							<el-option v-for="item in LocationCity" :key="item.id" :label="item.name"
								:value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="身高(cm)" required prop="height">
						<el-input-number v-model="formUser.height" controls-position="right"></el-input-number>
					</el-form-item>
					<el-form-item label="体重(kg)" required prop="weight">
						<el-input-number v-model="formUser.weight" controls-position="right"></el-input-number>
					</el-form-item>
					<el-form-item label="学历" required prop="education">
						<el-select v-model="formUser.education" placeholder="请选择学历">
							<el-option v-for="item in educationList" :key="item.id" :label="item.name"
								:value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="职业" required>
						<el-input clearable v-model="formUser.position" placeholder="请输入"></el-input>
					</el-form-item>
					<el-form-item label="年收入(w)" required>
						<el-input clearable type="number" v-model="formUser.annual_income" placeholder="请输入"></el-input>
					</el-form-item>
					<el-form-item label="是否购房" required>
						<el-radio-group v-model="formUser.house_purchase">
							<el-radio label="10">是</el-radio>
							<el-radio label="0">否</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="是否购车" required>
						<el-radio-group v-model="formUser.car_purchase">
							<el-radio label="10">是</el-radio>
							<el-radio label="0">否</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="小孩数量">
						<el-input clearable type="number" v-model="formUser.child" placeholder="请输入"></el-input>
					</el-form-item>
					<el-form-item label="爱情宣言">
						<el-input clearable type="textarea" v-model="formUser.declaration" placeholder="请填写爱情宣言"></el-input>
					</el-form-item>

				</el-form>




				<span slot="footer" class="dialog-footer">
					<el-button @click="isShowAdd = false">取 消</el-button>
					<el-button type="primary" @click="submitUser">确 定</el-button>
				</span>
			</el-dialog>

		</div>



	</div>
</template>

<script>
import topNavMenu from '@/components/TopNavMenu.vue';
import tagsNav from '@/components/common/tags-nav.vue';

import {
	educationList,
	genderList,
	marriageList,
	customerType,
	serviceType
} from '@/assets/js/formData.js'


import provice from '@/assets/js/province'
import city from '@/assets/js/city'


export default {
	components: {
		topNavMenu,
		tagsNav
	},
	data() {
		return {
			tableData: [],
			currentPage: 1, // 页数
			limit: 10,
			tableTotal: 0, // 总条数

			// 参数params
			keyword: '', // 搜索关键字
			customer_user_id: '',
			is_allocation: '', // 筛选分配
			gender: '', //性别
			type: '',
			service_type: '',
			region_id: '',
			shop_id: '',
			department_id: '',
			customer_user_id: '',




			genderList: genderList, // 性别
			educationList: educationList, // 学历
			customerType: customerType, // 销售服务类别
			serviceType: serviceType,

			OrganizaList: [], // 组织全部数据
			organSelectList: [],
			allocation_list: [{
				label: '不限',
				value: ''
			}, {
				label: '已分配',
				value: 10
			}, {
				label: '未分配',
				value: 0
			}], // 分配类型



			isShowAdd: false,


			marriageList: marriageList,
			educationList: educationList,
			provice: provice,
			cityList: [],
			LocationCity: [],


			selectNativeProvice: '',
			selectNativeCity: '',
			selectLocationProvice: '',
			selectLocationCity: '',

			formUser: {
				nickName: '',
				avatarUrl: '',
				gender: '',
				mobile: '',
				age: '18',
				marital_status: '',
				native_place: '',
				location_place: '',
				height: '',
				weight: '',
				education: '',
				position: '',
				annual_income: '',
				house_purchase: '',
				car_purchase: '',
				child: '',
				declaration: '',
				token: this.$store.state.token,
				shop_id: '',
				real_name:''
			},

			OrganizaList3: [], // 组织
			organSelectList3: [], // 选中

			formRules: {
				nickName: [{
					required: true,
					message: '请输入昵称',
					trigger: 'blur'
				}],
				gender: [{
					required: true,
					message: '请选择性别',
					trigger: 'change'
				}],
				mobile: [{
					required: true,
					message: '请输入手机号',
					trigger: 'blur'
				}],
				age: [{
					required: true,
					message: '请输入年龄',
					trigger: 'blur'
				}],
				marital_status: [{
					required: true,
					message: '请选择婚姻状况',
					trigger: 'change'
				}],
				height: [{
					required: true,
					message: '请输入身高',
					trigger: 'blur'
				}],
				weight: [{
					required: true,
					message: '请输入身高',
					trigger: 'blur'
				}],
				education: [{
					required: true,
					message: '请选择学历',
					trigger: 'change'
				}],
			},


			fileList: [], // 上传的文件

			role_id: '',

		}
	},
	created() {

		this.role_id = sessionStorage.getItem('role_id'),

			this.region_id = this.$route.query.region_id;
		this.shop_id = this.$route.query.shop_id;
		this.department_id = this.$route.query.department_id;
		this.customer_user_id = this.$route.query.customer_user_id;

		this.getCustomerList()
		this.getOrganizationList()

		this.getOrganizationList3()

	},
	methods: {

		handleCurrentChange(e) {
			// 当前页数、
			this.currentPage = e;
			this.getCustomerList();
		},
		handleSizeChange(e) {
			// console.log(`每页 ${e} 条`);
			this.limit = e;
			this.getCustomerList();
		},
		prevSize(e) {
			// console.log('上一页', e)
			this.currentPage = e;
		},
		nextSize(e) {
			// console.log('下一页', e)
			this.currentPage = e;
		},

		handleChange(e) {
			console.log(e);
			// this.customer_user_id = e[e.length - 1]

			if (e.length === 1) {
				this.region_id = e[e.length - 1];
				this.shop_id = ''
				this.department_id = ''
				this.customer_user_id = ''
			}
			if (e.length === 2) {
				this.shop_id = e[e.length - 1];
				this.region_id = ''
				this.department_id = ''
				this.customer_user_id = ''
			}
			if (e.length === 3) {
				this.department_id = e[e.length - 1];
				this.region_id = ''
				this.shop_id = ''
				this.customer_user_id = ''
			}
			if (e.length === 4) {
				this.customer_user_id = e[e.length - 1];
				this.region_id = ''
				this.shop_id = ''
				this.department_id = ''
			}

		},
		handleChange3(e) {
			console.log('123', e);
			this.formUser.shop_id = e[1]
			console.log(this.formUser.shop_id);
		},

		// 上传图片成功
		uploadSuccess(res) {
			if (res.code == 1) {
				this.formUser.avatarUrl = res.data.file_path;
			}
		},

		// 重置
		reset() {
			this.organSelectList = []
			this.keyword = ''
			this.is_allocation = ''
			this.gender = ''
			this.type = ''
			this.service_type = ''
			this.region_id = ''
			this.shop_id = ''
			this.department_id = ''
			this.customer_user_id = ''
		},

		getCustomerList() {

			this.$axios.get('/api/Customer/userList', {
				params: {
					token: this.$store.state.token,
					page: this.currentPage,
					limit: this.limit,
					keyword: this.keyword,
					is_allocation: this.is_allocation,
					gender: this.gender,
					type: this.type,
					service_type: this.service_type,
					region_id: this.region_id,
					shop_id: this.shop_id,
					department_id: this.department_id,
					customer_user_id: this.customer_user_id,
				}
			}).then(res => {
				console.log('客户列表', res);
				if (res.code == 1) {
					this.tableData = res.data.list.data;
					this.tableTotal = res.data.list.total;

				}

			})

		},

		// 获取组织
		getOrganizationList() {

			this.$axios.get('/manage/Customer/get_shop_tree', {
				params: {
					token: this.$store.state.token,
				}
			}).then(res => {
				console.log('组织', res)
				if (res.code == 1) {
					this.OrganizaList = res.data.shopCategory;
				}
			})
		},
		getOrganizationList3() {

			this.$axios.get('/manage/Customer/get_shop_tree3', {
				params: {
					token: this.$store.state.token,
				}
			}).then(res => {
				console.log('组织', res)
				if (res.code == 1) {
					this.OrganizaList3 = res.data.shopCategory;
				}
			})
		},


		// 条件查询
		conditionQuery() {
			this.currentPage = 1;
			this.getCustomerList()

		},

		// 跳转详情
		gotoDetail(user_id) {
			console.log(user_id)
			this.$router.push({
				name: 'customerDetail',
				query: {
					user_id: user_id
				}
			})
		},


		proviceChange(index, e, name) {
			console.log(index, e, city[0][e]);
			if (index == 1) {
				this.cityList = city[0][e]
				this.selectNativeCity = ''
			} else {
				this.LocationCity = city[0][e]
				this.selectLocationCity = ''
			}
			// console.log(this.cityList, 123);
		},
		cityChange(index, e) {
			// console.log(index,e);
			if (index == 1) {
				console.log(index, e, 555555);
				let result = this.provice.find(item => item.id == this.selectNativeProvice)
				console.log(result);
				console.log('列表', this.cityList);
				let cityResult = this.cityList.find(item => item.id == this.selectNativeCity)

				this.formUser.native_place = `${result.name}${cityResult.name}`
				console.log(this.formUser.native_place);
			} else {
				console.log(index, e, 66666);
				let result = this.provice.find(item => item.id == this.selectLocationProvice)
				console.log(result, this.selectLocationCity, this.cityList);
				console.log('列表', this.cityList);
				let cityResult = this.LocationCity.find(item => item.id == this.selectLocationCity)
				console.log(cityResult);

				this.formUser.location_place = `${result.name}${cityResult.name}`
				console.log(this.formUser.location_place);

			}
		},

		submitUser() {
			console.log(123);
			// 提交预校验
			this.$refs.userRef.validate(valid => {
				if (!valid) return;
				if(this.formUser.real_name==''){
					this.$message.error('请输入真实姓名')
					return
				}
				if (this.formUser.location_place == '') {
					this.$message.error('请选择所在地')
					return
				}
				this.$axios.post('manage/Customer/create_user', this.formUser).then(res => {

					if (res.code == 1) {
						console.log(res);
						this.$message.success('新增成功')
						this.currentPage = 1;
						this.getCustomerList()
						this.isShowAdd = false;
						this.$router.go(0)
					}else{
						this.$message.error(res.msg)
					}

				})

			});

		},


		// 移除
		handleRemove(file, fileList) {
			console.log(file, fileList);
			// this.fileList = []
		},
		handleSuccess(res, file, fileList) {
			console.log(res, file, fileList);
			if (res.code == 1) {
				this.$message.success(res.msg)
				this.fileList = []
			} else {
				this.$message.error(res.msg)
				this.fileList = []
			}
		},

		//
		submitUpload() {
			console.log('上传', this.fileList);
			this.$refs.upload.submit();
		},




	}

}
</script>

<style>
.el-avatar>img {
	width: 100% !important;
}
</style>

<style lang="less" scoped>
.res-content {
	padding: 30px;
}

.screen-container {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	background-color: #fff;
	padding: 20px 10px;
	border-radius: 4px;

	.screen-item {
		display: flex;
		align-items: center;
		margin-right: 20px;
		margin-bottom: 20px;

		.title {
			padding: 0 15px;
			font-size: 14px;
			font-weight: bold;
		}

		:deep .el-select {
			width: 80px;
		}

		.kefu-input {
			width: 150px;
		}
	}
}

.res-table {
	margin-top: 20px;
	background-color: #fff;
	padding: 20px;
	border-radius: 4px;

}


.page-container {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
	width: 100%;
	overflow-x: auto;
}
</style>