 const provice = [{
 		"id": 1,
 		"name": "北京市"
 	},
 	{
 		"id": 19,
 		"name": "天津市"
 	},
 	{
 		"id": 37,
 		"name": "河北省"
 	},
 	{
 		"id": 220,
 		"name": "山西省"
 	},
 	{
 		"id": 351,
 		"name": "内蒙古自治区"
 	},
 	{
 		"id": 466,
 		"name": "辽宁省"
 	},
 	{
 		"id": 585,
 		"name": "吉林省"
 	},
 	{
 		"id": 655,
 		"name": "黑龙江省"
 	},
 	{
 		"id": 801,
 		"name": "上海市"
 	},
 	{
 		"id": 820,
 		"name": "江苏省"
 	},
 	{
 		"id": 933,
 		"name": "浙江省"
 	},
 	{
 		"id": 1046,
 		"name": "安徽省"
 	},
 	{
 		"id": 1168,
 		"name": "福建省"
 	},
 	{
 		"id": 1263,
 		"name": "江西省"
 	},
 	{
 		"id": 1375,
 		"name": "山东省"
 	},
 	{
 		"id": 1532,
 		"name": "河南省"
 	},
 	{
 		"id": 1709,
 		"name": "湖北省"
 	},
 	{
 		"id": 1827,
 		"name": "湖南省"
 	},
 	{
 		"id": 1964,
 		"name": "广东省"
 	},
 	{
 		"id": 2162,
 		"name": "广西壮族自治区"
 	},
 	{
 		"id": 2291,
 		"name": "海南省"
 	},
 	{
 		"id": 2323,
 		"name": "重庆市"
 	},
 	{
 		"id": 2367,
 		"name": "四川省"
 	},
 	{
 		"id": 2572,
 		"name": "贵州省"
 	},
 	{
 		"id": 2670,
 		"name": "云南省"
 	},
 	{
 		"id": 2816,
 		"name": "西藏自治区"
 	},
 	{
 		"id": 2898,
 		"name": "陕西省"
 	},
 	{
 		"id": 3022,
 		"name": "甘肃省"
 	},
 	{
 		"id": 3126,
 		"name": "青海省"
 	},
 	{
 		"id": 3178,
 		"name": "宁夏回族自治区"
 	},
 	{
 		"id": 3206,
 		"name": "新疆维吾尔自治区"
 	},
 	{
 		"id": 3325,
 		"name": "台湾省"
 	},
 	{
 		"id": 3716,
 		"name": "香港特别行政区"
 	},
 	{
 		"id": 3738,
 		"name": "澳门特别行政区"
 	}
 ]

 export default provice