<template>
	<div class="div_">
		<!-- <el-scrollbar ref="scrollbar" :vertical="false" class="scrollbar_" @wheel.native.prevent="handleScroll">
			
		</el-scrollbar> -->
		
		<el-tag :ref="'tag'+ index" v-for="(item, index) in routerHistory" :key="index" :closable="index!=0"
			:type="nowRouter==item.path?'':'info'" @close="removeTag(item.path, index)" @click="tagClick(item.path,index)">
			{{item.title}}
		</el-tag>
	</div>
</template>
<script>
	import {
		mapGetters
	} from 'vuex'
	export default {
		data() {
			return {
				nowRouter: '',
				nowRouterIndex: 0, // 当前选中索引
				routerHistory: [],
				
				
			}
		},
		computed: {
			// ...mapGetters(["routerHistory"]),
		},
		watch: {
			$route: {
				handler: function(val, oldVal) {
					//console.log(val.path, '当前路由')
					this.nowRouter = val.path
					this.$store.commit('setNowRouter', val.path)
				},
				// 深度观察监听
				deep: true
			}
		},
		created() {
			// this.nowRouter = this.$store.state.app.nowRouter;
			// this.nowRouter = this.$store.state.app.nowRouter;
			// 通过当前路由获取路由下标
			
			//在页面渲染完成后调用计算滚动条位置
			this.$nextTick(() => {
				
				// this.nowRouter = this.$store.state.app.nowRouter;
				// this.routerHistory = this.$store.state.app.routerHistory;
				// this.$store.commit('setRouterHistory', this.$store.state.app.routerHistory)
				
				if(this.routerHistory.length > 0) {
					// this.countSroll(nowRouterIndex)
				} 
				
			})
		},
		activated() {
			// //console.log('缓存组件被激活了444', this.$store.state.app.routerHistory)
			this.nowRouter = this.$store.state.app.nowRouter;
			//console.log(this.nowRouter, '777777777')
			
			this.routerHistory = this.$store.state.app.routerHistory;
			this.nowRouterIndex = this.routerHistory.findIndex(item => item.path == this.nowRouter);
			
		},
		deactivated() {
			// //console.log('缓存组件被失效了444', this.$store.state.app.routerHistory)
			this.$store.commit('setRouterHistory', this.$store.state.app.routerHistory)
		},
		methods: {
			removeTag(routerPath,index) {
				// //console.log('删除的当前索引', index, '当前索引', this.nowRouterIndex)
				// //console.log('当前导航路由', this.$route)
				
				if(index < this.nowRouterIndex) {
					// 删除tag返回新路由历史数组并存入vuex
					let newArr = this.routerHistory.filter(item => item.path !== routerPath);
					this.$store.commit('setRouterHistory', newArr)
					this.$router.push({
						path: this.routerHistory[index - 1].path
					})
				}
				
				if(index > this.nowRouterIndex) {
					// 删除tag返回新路由历史数组并存入vuex
					let newArr = this.routerHistory.filter(item => item.path !== routerPath);
					this.$store.commit('setRouterHistory', newArr)
					this.$router.push({
						path: newArr[newArr.length - 1].path,
					})
				}
				
				// // 如果删除的路由是当前路由，路由跳转至目前路由历史数组最后一个路由
				if (this.$route.path == routerPath) {
					let newArr = this.routerHistory.filter(item => item.path !== routerPath);
					this.$store.commit('setRouterHistory', newArr)
					this.$router.push({
						// path: newArr[newArr.length - 1].path,
						path: this.routerHistory[index - 1].path
					})
				}
				
				
				// 删除tag返回新路由历史数组并存入vuex
				// let newArr = this.routerHistory.filter(item => item.path !== routerPath);
				// //console.log('删除的路由', newArr)
				
				// this.$store.commit('setRouterHistory', newArr)
				// // 如果删除的路由是当前路由，路由跳转至目前路由历史数组最后一个路由
				// if (this.$route.path == routerPath) {
				// 	//console.log('2222222222222222')
				// 	this.$router.push({
				// 		// path: newArr[newArr.length - 1].path,
				// 		path: this.routerHistory[index - 1].path
				// 	})
				// }
				// else {
				// 	//console.log('33333333', this.routerHistory)
					
				// 	this.$router.push({
				// 		path: this.routerHistory[index - 1].path
				// 	})
				// }
			},
			tagClick(path, index) {
				
				if(path == this.nowRouter) {
					return
				}
				
				// this.countSroll(index);
				this.$router.push(path);
			},
			countSroll(index) {
				// 获取tag宽度
				let tagWidth = this.$refs['tag' + index][0].$el.offsetWidth;
				// 获取tag距离右边宽度
				let tagMargin = this.$refs['tag' + index][0].$el.offsetLeft;
				// 获取sroll宽度
				let srollWidth = this.$refs.scrollbar.$el.offsetWidth;
				// 如果tag距离右边+tag宽度+20左右已经大于sroll宽度，表示tag已经到了尽头了
				if ((tagMargin + tagWidth + 20) > srollWidth) {
					this.$refs.scrollbar.$refs.wrap.scrollLeft = tagMargin
				} else {
					this.$refs.scrollbar.$refs.wrap.scrollLeft = 0
				}
			},
			handleScroll(e) {
				const eventDelta = e.wheelDelta || -e.deltaY * 40
				this.$refs.scrollbar.$refs.wrap.scrollLeft = this.$refs.scrollbar.$refs.wrap.scrollLeft + eventDelta / 4
			},
		},
	}
</script>
<style lang="less" scoped>
	.div_ {
		height: 64px;
		padding: 10px 15px;
		background-color: white;
		box-sizing: border-box;
		overflow-x: auto;
		min-width: 1440px;
		white-space: nowrap;
		border-bottom: 2px solid #F0F2F5;
		.scrollbar_ {
			height: 100%;
			width: 100%;
			white-space: nowrap;
 
			::v-deep .el-scrollbar__view {
				height: 100%;
				display: flex;
				align-items: center;
			}
		}
	}
 
	.el-tag {
		margin-left: 10px;
		cursor: pointer
	}
 
	:first-child {
		margin: 0;
	}
	
	
	
</style>