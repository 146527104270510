<template>

	<div id="app">
		<!-- 路由占位符-->
		<keep-alive>
			<router-view></router-view>
		</keep-alive>

	</div>
</template>


<script>
 import Vue from "vue"
    import VueTinymce from "@packy-tang/vue-tinymce"

    //安装组件
    Vue.use(VueTinymce)
	export default {
		name: 'app',
		mounted() {
			window.addEventListener("load", () => {
				//写入你想要执行的代码
				console.log('整个页面刷新了')
				// let nowRouter = this.$store.state.app.nowRouter;
				// this.$store.commit('setRouterHistory', newArr)


			});
			console.log('appmount');
		}

	}

</script>

<style>
	#app,
	html {
		height: 100%;
		margin: 0;
		padding: 0;
		min-width: 1440px;
	}

	*,
	div {
		box-sizing: border-box;
	}
</style>