export default {
	setValue(key, value, type) {
		if (typeof value == "object") {
			// 如果是对象，就转字符串存储
			value = JSON.stringify(value)
		}
		return window[type || 'localStorage'].setItem(key, value);
	},
	getValue(key, type) {
		const data = window[type || 'localStorage'].getItem(key);
		if (data) {
			let value = data;
			if ((data.indexOf('{') === 0 || data.indexOf('[') === 0) && data.indexOf(':') >= 0) {
				// 如果是字符串对象，就转对象
				value = JSON.parse(data)
			}
			return value
		} else {
			return false
		}
	},
	cleanValue(key, type) {
		window[type || 'localStorage'].removeItem(key);
	}
}