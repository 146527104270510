const city = [{
	"1": [{
		"id": 2,
		"name": "北京市"
	}],
	"19": [{
		"id": 20,
		"name": "天津市"
	}],
	"37": [{
			"id": 38,
			"name": "石家庄市"
		},
		{
			"id": 61,
			"name": "唐山市"
		},
		{
			"id": 76,
			"name": "秦皇岛市"
		},
		{
			"id": 84,
			"name": "邯郸市"
		},
		{
			"id": 104,
			"name": "邢台市"
		},
		{
			"id": 124,
			"name": "保定市"
		},
		{
			"id": 150,
			"name": "张家口市"
		},
		{
			"id": 168,
			"name": "承德市"
		},
		{
			"id": 180,
			"name": "沧州市"
		},
		{
			"id": 197,
			"name": "廊坊市"
		},
		{
			"id": 208,
			"name": "衡水市"
		}
	],
	"220": [{
			"id": 221,
			"name": "太原市"
		},
		{
			"id": 232,
			"name": "大同市"
		},
		{
			"id": 244,
			"name": "阳泉市"
		},
		{
			"id": 250,
			"name": "长治市"
		},
		{
			"id": 264,
			"name": "晋城市"
		},
		{
			"id": 271,
			"name": "朔州市"
		},
		{
			"id": 278,
			"name": "晋中市"
		},
		{
			"id": 290,
			"name": "运城市"
		},
		{
			"id": 304,
			"name": "忻州市"
		},
		{
			"id": 319,
			"name": "临汾市"
		},
		{
			"id": 337,
			"name": "吕梁市"
		}
	],
	"351": [{
			"id": 352,
			"name": "呼和浩特市"
		},
		{
			"id": 362,
			"name": "包头市"
		},
		{
			"id": 372,
			"name": "乌海市"
		},
		{
			"id": 376,
			"name": "赤峰市"
		},
		{
			"id": 389,
			"name": "通辽市"
		},
		{
			"id": 398,
			"name": "鄂尔多斯市"
		},
		{
			"id": 407,
			"name": "呼伦贝尔市"
		},
		{
			"id": 422,
			"name": "巴彦淖尔市"
		},
		{
			"id": 430,
			"name": "乌兰察布市"
		},
		{
			"id": 442,
			"name": "兴安盟"
		},
		{
			"id": 449,
			"name": "锡林郭勒盟"
		},
		{
			"id": 462,
			"name": "阿拉善盟"
		}
	],
	"466": [{
			"id": 467,
			"name": "沈阳市"
		},
		{
			"id": 481,
			"name": "大连市"
		},
		{
			"id": 492,
			"name": "鞍山市"
		},
		{
			"id": 500,
			"name": "抚顺市"
		},
		{
			"id": 508,
			"name": "本溪市"
		},
		{
			"id": 515,
			"name": "丹东市"
		},
		{
			"id": 522,
			"name": "锦州市"
		},
		{
			"id": 530,
			"name": "营口市"
		},
		{
			"id": 537,
			"name": "阜新市"
		},
		{
			"id": 545,
			"name": "辽阳市"
		},
		{
			"id": 553,
			"name": "盘锦市"
		},
		{
			"id": 558,
			"name": "铁岭市"
		},
		{
			"id": 566,
			"name": "朝阳市"
		},
		{
			"id": 574,
			"name": "葫芦岛市"
		},
		{
			"id": 581,
			"name": "金普新区"
		}
	],
	"585": [{
			"id": 586,
			"name": "长春市"
		},
		{
			"id": 597,
			"name": "吉林市"
		},
		{
			"id": 607,
			"name": "四平市"
		},
		{
			"id": 614,
			"name": "辽源市"
		},
		{
			"id": 619,
			"name": "通化市"
		},
		{
			"id": 627,
			"name": "白山市"
		},
		{
			"id": 634,
			"name": "松原市"
		},
		{
			"id": 640,
			"name": "白城市"
		},
		{
			"id": 646,
			"name": "延边朝鲜族自治州"
		}
	],
	"655": [{
			"id": 656,
			"name": "哈尔滨市"
		},
		{
			"id": 675,
			"name": "齐齐哈尔市"
		},
		{
			"id": 692,
			"name": "鸡西市"
		},
		{
			"id": 702,
			"name": "鹤岗市"
		},
		{
			"id": 711,
			"name": "双鸭山市"
		},
		{
			"id": 720,
			"name": "大庆市"
		},
		{
			"id": 730,
			"name": "伊春市"
		},
		{
			"id": 748,
			"name": "佳木斯市"
		},
		{
			"id": 759,
			"name": "七台河市"
		},
		{
			"id": 764,
			"name": "牡丹江市"
		},
		{
			"id": 775,
			"name": "黑河市"
		},
		{
			"id": 782,
			"name": "绥化市"
		},
		{
			"id": 793,
			"name": "大兴安岭地区"
		}
	],
	"801": [{
		"id": 802,
		"name": "上海市"
	}],
	"820": [{
			"id": 821,
			"name": "南京市"
		},
		{
			"id": 833,
			"name": "无锡市"
		},
		{
			"id": 842,
			"name": "徐州市"
		},
		{
			"id": 853,
			"name": "常州市"
		},
		{
			"id": 861,
			"name": "苏州市"
		},
		{
			"id": 871,
			"name": "南通市"
		},
		{
			"id": 880,
			"name": "连云港市"
		},
		{
			"id": 887,
			"name": "淮安市"
		},
		{
			"id": 896,
			"name": "盐城市"
		},
		{
			"id": 906,
			"name": "扬州市"
		},
		{
			"id": 913,
			"name": "镇江市"
		},
		{
			"id": 920,
			"name": "泰州市"
		},
		{
			"id": 927,
			"name": "宿迁市"
		}
	],
	"933": [{
			"id": 934,
			"name": "杭州市"
		},
		{
			"id": 948,
			"name": "宁波市"
		},
		{
			"id": 960,
			"name": "温州市"
		},
		{
			"id": 972,
			"name": "嘉兴市"
		},
		{
			"id": 980,
			"name": "湖州市"
		},
		{
			"id": 986,
			"name": "绍兴市"
		},
		{
			"id": 993,
			"name": "金华市"
		},
		{
			"id": 1003,
			"name": "衢州市"
		},
		{
			"id": 1010,
			"name": "舟山市"
		},
		{
			"id": 1015,
			"name": "台州市"
		},
		{
			"id": 1025,
			"name": "丽水市"
		},
		{
			"id": 1035,
			"name": "舟山群岛新区"
		}
	],
	"1046": [{
			"id": 1047,
			"name": "合肥市"
		},
		{
			"id": 1057,
			"name": "芜湖市"
		},
		{
			"id": 1066,
			"name": "蚌埠市"
		},
		{
			"id": 1074,
			"name": "淮南市"
		},
		{
			"id": 1081,
			"name": "马鞍山市"
		},
		{
			"id": 1088,
			"name": "淮北市"
		},
		{
			"id": 1093,
			"name": "铜陵市"
		},
		{
			"id": 1098,
			"name": "安庆市"
		},
		{
			"id": 1110,
			"name": "黄山市"
		},
		{
			"id": 1118,
			"name": "滁州市"
		},
		{
			"id": 1127,
			"name": "阜阳市"
		},
		{
			"id": 1136,
			"name": "宿州市"
		},
		{
			"id": 1142,
			"name": "六安市"
		},
		{
			"id": 1150,
			"name": "亳州市"
		},
		{
			"id": 1155,
			"name": "池州市"
		},
		{
			"id": 1160,
			"name": "宣城市"
		}
	],
	"1168": [{
			"id": 1169,
			"name": "福州市"
		},
		{
			"id": 1183,
			"name": "厦门市"
		},
		{
			"id": 1190,
			"name": "莆田市"
		},
		{
			"id": 1196,
			"name": "三明市"
		},
		{
			"id": 1209,
			"name": "泉州市"
		},
		{
			"id": 1222,
			"name": "漳州市"
		},
		{
			"id": 1234,
			"name": "南平市"
		},
		{
			"id": 1245,
			"name": "龙岩市"
		},
		{
			"id": 1253,
			"name": "宁德市"
		}
	],
	"1263": [{
			"id": 1264,
			"name": "南昌市"
		},
		{
			"id": 1274,
			"name": "景德镇市"
		},
		{
			"id": 1279,
			"name": "萍乡市"
		},
		{
			"id": 1285,
			"name": "九江市"
		},
		{
			"id": 1299,
			"name": "新余市"
		},
		{
			"id": 1302,
			"name": "鹰潭市"
		},
		{
			"id": 1306,
			"name": "赣州市"
		},
		{
			"id": 1325,
			"name": "吉安市"
		},
		{
			"id": 1339,
			"name": "宜春市"
		},
		{
			"id": 1350,
			"name": "抚州市"
		},
		{
			"id": 1362,
			"name": "上饶市"
		}
	],
	"1375": [{
			"id": 1376,
			"name": "济南市"
		},
		{
			"id": 1387,
			"name": "青岛市"
		},
		{
			"id": 1399,
			"name": "淄博市"
		},
		{
			"id": 1408,
			"name": "枣庄市"
		},
		{
			"id": 1415,
			"name": "东营市"
		},
		{
			"id": 1421,
			"name": "烟台市"
		},
		{
			"id": 1434,
			"name": "潍坊市"
		},
		{
			"id": 1447,
			"name": "济宁市"
		},
		{
			"id": 1459,
			"name": "泰安市"
		},
		{
			"id": 1466,
			"name": "威海市"
		},
		{
			"id": 1471,
			"name": "日照市"
		},
		{
			"id": 1476,
			"name": "莱芜市"
		},
		{
			"id": 1479,
			"name": "临沂市"
		},
		{
			"id": 1492,
			"name": "德州市"
		},
		{
			"id": 1504,
			"name": "聊城市"
		},
		{
			"id": 1513,
			"name": "滨州市"
		},
		{
			"id": 1522,
			"name": "菏泽市"
		}
	],
	"1532": [{
			"id": 1533,
			"name": "郑州市"
		},
		{
			"id": 1546,
			"name": "开封市"
		},
		{
			"id": 1556,
			"name": "洛阳市"
		},
		{
			"id": 1572,
			"name": "平顶山市"
		},
		{
			"id": 1583,
			"name": "安阳市"
		},
		{
			"id": 1593,
			"name": "鹤壁市"
		},
		{
			"id": 1599,
			"name": "新乡市"
		},
		{
			"id": 1612,
			"name": "焦作市"
		},
		{
			"id": 1623,
			"name": "濮阳市"
		},
		{
			"id": 1630,
			"name": "许昌市"
		},
		{
			"id": 1637,
			"name": "漯河市"
		},
		{
			"id": 1643,
			"name": "三门峡市"
		},
		{
			"id": 1650,
			"name": "南阳市"
		},
		{
			"id": 1664,
			"name": "商丘市"
		},
		{
			"id": 1674,
			"name": "信阳市"
		},
		{
			"id": 1685,
			"name": "周口市"
		},
		{
			"id": 1696,
			"name": "驻马店市"
		},
		{
			"id": 1707,
			"name": "直辖县级"
		}
	],
	"1709": [{
			"id": 1710,
			"name": "武汉市"
		},
		{
			"id": 1724,
			"name": "黄石市"
		},
		{
			"id": 1731,
			"name": "十堰市"
		},
		{
			"id": 1740,
			"name": "宜昌市"
		},
		{
			"id": 1754,
			"name": "襄阳市"
		},
		{
			"id": 1764,
			"name": "鄂州市"
		},
		{
			"id": 1768,
			"name": "荆门市"
		},
		{
			"id": 1774,
			"name": "孝感市"
		},
		{
			"id": 1782,
			"name": "荆州市"
		},
		{
			"id": 1791,
			"name": "黄冈市"
		},
		{
			"id": 1802,
			"name": "咸宁市"
		},
		{
			"id": 1809,
			"name": "随州市"
		},
		{
			"id": 1813,
			"name": "恩施土家族苗族自治州"
		},
		{
			"id": 1822,
			"name": "直辖县级"
		}
	],
	"1827": [{
			"id": 1828,
			"name": "长沙市"
		},
		{
			"id": 1838,
			"name": "株洲市"
		},
		{
			"id": 1848,
			"name": "湘潭市"
		},
		{
			"id": 1854,
			"name": "衡阳市"
		},
		{
			"id": 1867,
			"name": "邵阳市"
		},
		{
			"id": 1880,
			"name": "岳阳市"
		},
		{
			"id": 1890,
			"name": "常德市"
		},
		{
			"id": 1900,
			"name": "张家界市"
		},
		{
			"id": 1905,
			"name": "益阳市"
		},
		{
			"id": 1912,
			"name": "郴州市"
		},
		{
			"id": 1924,
			"name": "永州市"
		},
		{
			"id": 1936,
			"name": "怀化市"
		},
		{
			"id": 1949,
			"name": "娄底市"
		},
		{
			"id": 1955,
			"name": "湘西土家族苗族自治州"
		}
	],
	"1964": [{
			"id": 1965,
			"name": "广州市"
		},
		{
			"id": 1977,
			"name": "韶关市"
		},
		{
			"id": 1988,
			"name": "深圳市"
		},
		{
			"id": 1999,
			"name": "珠海市"
		},
		{
			"id": 2003,
			"name": "汕头市"
		},
		{
			"id": 2011,
			"name": "佛山市"
		},
		{
			"id": 2017,
			"name": "江门市"
		},
		{
			"id": 2025,
			"name": "湛江市"
		},
		{
			"id": 2035,
			"name": "茂名市"
		},
		{
			"id": 2041,
			"name": "肇庆市"
		},
		{
			"id": 2050,
			"name": "惠州市"
		},
		{
			"id": 2056,
			"name": "梅州市"
		},
		{
			"id": 2065,
			"name": "汕尾市"
		},
		{
			"id": 2070,
			"name": "河源市"
		},
		{
			"id": 2077,
			"name": "阳江市"
		},
		{
			"id": 2082,
			"name": "清远市"
		},
		{
			"id": 2091,
			"name": "东莞市"
		},
		{
			"id": 2123,
			"name": "中山市"
		},
		{
			"id": 2146,
			"name": "潮州市"
		},
		{
			"id": 2150,
			"name": "揭阳市"
		},
		{
			"id": 2156,
			"name": "云浮市"
		}
	],
	"2162": [{
			"id": 2163,
			"name": "南宁市"
		},
		{
			"id": 2177,
			"name": "柳州市"
		},
		{
			"id": 2189,
			"name": "桂林市"
		},
		{
			"id": 2207,
			"name": "梧州市"
		},
		{
			"id": 2215,
			"name": "北海市"
		},
		{
			"id": 2220,
			"name": "防城港市"
		},
		{
			"id": 2225,
			"name": "钦州市"
		},
		{
			"id": 2230,
			"name": "贵港市"
		},
		{
			"id": 2236,
			"name": "玉林市"
		},
		{
			"id": 2245,
			"name": "百色市"
		},
		{
			"id": 2258,
			"name": "贺州市"
		},
		{
			"id": 2264,
			"name": "河池市"
		},
		{
			"id": 2276,
			"name": "来宾市"
		},
		{
			"id": 2283,
			"name": "崇左市"
		}
	],
	"2291": [{
			"id": 2292,
			"name": "海口市"
		},
		{
			"id": 2297,
			"name": "三亚市"
		},
		{
			"id": 2302,
			"name": "三沙市"
		},
		{
			"id": 2306,
			"name": "直辖县级"
		},
		{
			"id": 4018,
			"name": "儋州市"
		}
	],
	"2323": [{
			"id": 2324,
			"name": "重庆市"
		},
		{
			"id": 2363,
			"name": "县"
		}
	],
	"2367": [{
			"id": 2368,
			"name": "成都市"
		},
		{
			"id": 2388,
			"name": "自贡市"
		},
		{
			"id": 2395,
			"name": "攀枝花市"
		},
		{
			"id": 2401,
			"name": "泸州市"
		},
		{
			"id": 2409,
			"name": "德阳市"
		},
		{
			"id": 2416,
			"name": "绵阳市"
		},
		{
			"id": 2426,
			"name": "广元市"
		},
		{
			"id": 2434,
			"name": "遂宁市"
		},
		{
			"id": 2440,
			"name": "内江市"
		},
		{
			"id": 2446,
			"name": "乐山市"
		},
		{
			"id": 2458,
			"name": "南充市"
		},
		{
			"id": 2468,
			"name": "眉山市"
		},
		{
			"id": 2475,
			"name": "宜宾市"
		},
		{
			"id": 2486,
			"name": "广安市"
		},
		{
			"id": 2493,
			"name": "达州市"
		},
		{
			"id": 2501,
			"name": "雅安市"
		},
		{
			"id": 2510,
			"name": "巴中市"
		},
		{
			"id": 2516,
			"name": "资阳市"
		},
		{
			"id": 2521,
			"name": "阿坝藏族羌族自治州"
		},
		{
			"id": 2535,
			"name": "甘孜藏族自治州"
		},
		{
			"id": 2554,
			"name": "凉山彝族自治州"
		}
	],
	"2572": [{
			"id": 2573,
			"name": "贵阳市"
		},
		{
			"id": 2584,
			"name": "六盘水市"
		},
		{
			"id": 2589,
			"name": "遵义市"
		},
		{
			"id": 2604,
			"name": "安顺市"
		},
		{
			"id": 2611,
			"name": "毕节市"
		},
		{
			"id": 2620,
			"name": "铜仁市"
		},
		{
			"id": 2631,
			"name": "黔西南布依族苗族自治州"
		},
		{
			"id": 2640,
			"name": "黔东南苗族侗族自治州"
		},
		{
			"id": 2657,
			"name": "黔南布依族苗族自治州"
		}
	],
	"2670": [{
			"id": 2671,
			"name": "昆明市"
		},
		{
			"id": 2686,
			"name": "曲靖市"
		},
		{
			"id": 2696,
			"name": "玉溪市"
		},
		{
			"id": 2706,
			"name": "保山市"
		},
		{
			"id": 2712,
			"name": "昭通市"
		},
		{
			"id": 2724,
			"name": "丽江市"
		},
		{
			"id": 2730,
			"name": "普洱市"
		},
		{
			"id": 2741,
			"name": "临沧市"
		},
		{
			"id": 2750,
			"name": "楚雄彝族自治州"
		},
		{
			"id": 2761,
			"name": "红河哈尼族彝族自治州"
		},
		{
			"id": 2775,
			"name": "文山壮族苗族自治州"
		},
		{
			"id": 2784,
			"name": "西双版纳傣族自治州"
		},
		{
			"id": 2788,
			"name": "大理白族自治州"
		},
		{
			"id": 2801,
			"name": "德宏傣族景颇族自治州"
		},
		{
			"id": 2807,
			"name": "怒江傈僳族自治州"
		},
		{
			"id": 2812,
			"name": "迪庆藏族自治州"
		}
	],
	"2816": [{
			"id": 2817,
			"name": "拉萨市"
		},
		{
			"id": 2826,
			"name": "日喀则市"
		},
		{
			"id": 2845,
			"name": "昌都市"
		},
		{
			"id": 2857,
			"name": "山南市"
		},
		{
			"id": 2870,
			"name": "那曲地区"
		},
		{
			"id": 2882,
			"name": "阿里地区"
		},
		{
			"id": 2890,
			"name": "林芝市"
		}
	],
	"2898": [{
			"id": 2899,
			"name": "西安市"
		},
		{
			"id": 2913,
			"name": "铜川市"
		},
		{
			"id": 2918,
			"name": "宝鸡市"
		},
		{
			"id": 2931,
			"name": "咸阳市"
		},
		{
			"id": 2946,
			"name": "渭南市"
		},
		{
			"id": 2958,
			"name": "延安市"
		},
		{
			"id": 2972,
			"name": "汉中市"
		},
		{
			"id": 2984,
			"name": "榆林市"
		},
		{
			"id": 2997,
			"name": "安康市"
		},
		{
			"id": 3008,
			"name": "商洛市"
		},
		{
			"id": 3016,
			"name": "西咸新区"
		}
	],
	"3022": [{
			"id": 3023,
			"name": "兰州市"
		},
		{
			"id": 3032,
			"name": "嘉峪关市"
		},
		{
			"id": 3036,
			"name": "金昌市"
		},
		{
			"id": 3039,
			"name": "白银市"
		},
		{
			"id": 3045,
			"name": "天水市"
		},
		{
			"id": 3053,
			"name": "武威市"
		},
		{
			"id": 3058,
			"name": "张掖市"
		},
		{
			"id": 3065,
			"name": "平凉市"
		},
		{
			"id": 3073,
			"name": "酒泉市"
		},
		{
			"id": 3081,
			"name": "庆阳市"
		},
		{
			"id": 3090,
			"name": "定西市"
		},
		{
			"id": 3098,
			"name": "陇南市"
		},
		{
			"id": 3108,
			"name": "临夏回族自治州"
		},
		{
			"id": 3117,
			"name": "甘南藏族自治州"
		}
	],
	"3126": [{
			"id": 3127,
			"name": "西宁市"
		},
		{
			"id": 3135,
			"name": "海东市"
		},
		{
			"id": 3142,
			"name": "海北藏族自治州"
		},
		{
			"id": 3147,
			"name": "黄南藏族自治州"
		},
		{
			"id": 3152,
			"name": "海南藏族自治州"
		},
		{
			"id": 3158,
			"name": "果洛藏族自治州"
		},
		{
			"id": 3165,
			"name": "玉树藏族自治州"
		},
		{
			"id": 3172,
			"name": "海西蒙古族藏族自治州"
		}
	],
	"3178": [{
			"id": 3179,
			"name": "银川市"
		},
		{
			"id": 3186,
			"name": "石嘴山市"
		},
		{
			"id": 3190,
			"name": "吴忠市"
		},
		{
			"id": 3196,
			"name": "固原市"
		},
		{
			"id": 3202,
			"name": "中卫市"
		}
	],
	"3206": [{
			"id": 3207,
			"name": "乌鲁木齐市"
		},
		{
			"id": 3216,
			"name": "克拉玛依市"
		},
		{
			"id": 3221,
			"name": "吐鲁番地区"
		},
		{
			"id": 3225,
			"name": "哈密地区"
		},
		{
			"id": 3229,
			"name": "昌吉回族自治州"
		},
		{
			"id": 3237,
			"name": "博尔塔拉蒙古自治州"
		},
		{
			"id": 3242,
			"name": "巴音郭楞蒙古自治州"
		},
		{
			"id": 3252,
			"name": "阿克苏地区"
		},
		{
			"id": 3262,
			"name": "克孜勒苏柯尔克孜自治州"
		},
		{
			"id": 3267,
			"name": "喀什地区"
		},
		{
			"id": 3280,
			"name": "和田地区"
		},
		{
			"id": 3289,
			"name": "伊犁哈萨克自治州"
		},
		{
			"id": 3301,
			"name": "塔城地区"
		},
		{
			"id": 3309,
			"name": "阿勒泰地区"
		},
		{
			"id": 3317,
			"name": "直辖县级"
		}
	],
	"3325": [{
			"id": 3326,
			"name": "台北市"
		},
		{
			"id": 3339,
			"name": "高雄市"
		},
		{
			"id": 3378,
			"name": "基隆市"
		},
		{
			"id": 3386,
			"name": "台中市"
		},
		{
			"id": 3416,
			"name": "台南市"
		},
		{
			"id": 3454,
			"name": "新竹市"
		},
		{
			"id": 3458,
			"name": "嘉义市"
		},
		{
			"id": 3461,
			"name": "新北市"
		},
		{
			"id": 3491,
			"name": "宜兰县"
		},
		{
			"id": 3504,
			"name": "桃园县"
		},
		{
			"id": 3518,
			"name": "新竹县"
		},
		{
			"id": 3532,
			"name": "苗栗县"
		},
		{
			"id": 3551,
			"name": "彰化县"
		},
		{
			"id": 3578,
			"name": "南投县"
		},
		{
			"id": 3592,
			"name": "云林县"
		},
		{
			"id": 3613,
			"name": "嘉义县"
		},
		{
			"id": 3632,
			"name": "屏东县"
		},
		{
			"id": 3666,
			"name": "台东县"
		},
		{
			"id": 3683,
			"name": "花莲县"
		},
		{
			"id": 3697,
			"name": "澎湖县"
		},
		{
			"id": 3704,
			"name": "金门县"
		},
		{
			"id": 3711,
			"name": "连江县"
		}
	],
	"3716": [{
		"id": 3999,
		"name": "香港特别行政区"
	}],
	"3738": [{
			"id": 3739,
			"name": "澳门半岛"
		},
		{
			"id": 3745,
			"name": "氹仔岛"
		},
		{
			"id": 3747,
			"name": "路环岛"
		}
	]
}]


// 导出
export default city