<template>
	<div class="login-container">

		<!-- 登录表单 -->
		<div class="login-form">
			<el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules">
				<img class="login-image" src="../assets/images/logo.png" alt="">
		
				<div class="title">
					登录
					<span>Login</span>
				</div>
				
				<el-form-item prop="username" >
					<el-input placeholder="账号" v-model="loginForm.username" class="username" prefix-icon="el-icon-user"></el-input>
				</el-form-item>
				<el-form-item prop="password">
					<el-input show-password placeholder="密码" v-model="loginForm.password" class="password" prefix-icon="el-icon-key"></el-input>
				</el-form-item>
				
				<div class="tips">请使用Google Chrome浏览器</div>
				<!-- 登录 -->
				<el-button class="el-button loginBtn" @click="login">登录</el-button>
			</el-form>
		</div>

	</div>
</template>

<script>
	import {
		mapMutations
	} from 'vuex';
	
	
	export default {
		name: 'login',
		data() {
			return {
				loginForm: {
					username: '',
					password: '', 
				},
				loginFormRules: {
					// 表单验证
					username: [
						{ required: true, message: '请输入账号', trigger: 'blur' },
						{ min: 1, max: 16, message: '长度在 1 到 16 个字符', trigger: 'blur' }
					],
					password: [
						{ required: true, message: '请输入密码', trigger: 'blur' },
						// { min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur' }
					]
				}
			}
		},
		methods: {
			...mapMutations(['setToken','saveTokenStorage', 'setUserId', 'setRoleId']),
			
			
			// 登录
			login() {
				let that = this;
				
				// 提交预校验
				this.$refs.loginFormRef.validate(valid => {
					if(!valid) return;
					
					
					this.$axios.post('/manage/customer/login', this.loginForm).then(res => {
						console.log('登录', res)
						if(res.code == 1) {
							that.$message.success('登录成功');
							
							// 保存token
							// window.sessionStorage.setItem('token', res.data.token);
							// window.sessionStorage.setItem('user_id', res.data.user_id);
							
							this.setToken(res.data.token)
							this.setUserId(res.data.user_id)
							// this.setRoleId(res.data.role_id)
							this.saveTokenStorage()
							// this.$store.commit('setRouterHistory', '/dashboard')
							setTimeout(() => {
								that.$router.push('/dashboard') 
							}, 1000)
							
						} else {
							that.$message.error(res.msg)
						}
					})
					
					
				});
				
				
			},

		}
	}
</script>

<style lang="less" scoped>
	
	.login-container {
		height: 100%;
		width: 100%;
		background: url('../assets/images/login-bg.jpg') no-repeat 100%;
		background-size: cover;
		overflow: hidden;
	}

	.login-form {
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: fixed;
		right: 55px;
		top: 50%;
		transform: translateY(-50%);
		max-width: 500px;
		padding: 0 140px;
		height: 90%;
		min-height: 600px;
		background: url('../assets/images/login-form-bg.png') no-repeat 100%;
		background-size: cover;
		border-radius: 24px;
		
		.title {
			margin: 40px 0 10px 0;
			font-size: 14px;
			color: #fff;
			span {
				color: #878D93;
			}
		}
		
	}

	.login-image {
		display: block;
		position: relative;
		margin: 20px auto;
		width: 120px;
		height: 120px;
	}
	
	.el-form-item__content {
		line-height: 36px;
	}
	:deep .el-input__inner {
		height: 32px;
		background-color: #3B4741;
		border: none;
		font-size: 13px;
		color: #fff;
	}
	
	:deep .el-form-item {
		margin-bottom: 18px;
	}
	
	.loginBtn {
		width: 212px;
		height: 32px;
		line-height: 32px;
		padding: 0 !important;
		background: #ff4d65 !important;
		border: none !important;
		border-radius: 4px;
		font-size: 14px;
		font-family: Microsoft YaHei UI-Regular,Microsoft YaHei UI;
		font-weight: 400;
		color: #fff !important;
		padding: 0;
		border: none;
		margin: 20px auto 0 auto;
	}
	
	.tips {
		margin: 20px auto 20px;
		font-size: 14px;
		color: #fff;
	}
	
	// .username, .password {
	// 	font-size: 13px !important;
	// 	color: #fff;
	// }
	
</style>


