const utils = {
	formatTime(time) {
		
		let date = new Date(time)
		let year = date.getFullYear()
		let month = date.getMonth() + 1
		let day = date.getDay()
		
		return `${year}-${month}-${day}`
	}
}



export default utils;