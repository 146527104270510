import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


Vue.config.productionTip = false


// 导入 element-ui 依赖
import './plugins/element.js';
// 引入全局样式文件
import './assets/css/global.css';

import utils from './assets/js/utils.js';
Vue.prototype.$utils = utils;


// 第三方电话样式，js文件 start
// import './assets/css/cti.css';

// import './assets/js/jq/jquery.js';
// import './assets/moor/moor.js';
// import './assets/js/m7Util.js';
// import './assets/js/moortools.js';
// import './assets/js/m7PhoneBar.js';
// import './assets/js/objectDeclare.js';
// import './assets/js/recordError.js';
// import './assets/js/m7Monitor.js';
// import './assets/js/crypto/md5.min.js';
// import './assets/js/crypto/base64.min.js';


// 第三方电话样式，js文件 end




/* 全局注册组件 */
import topNavMenu from '@/components/TopNavMenu.vue';
Vue.component('topNavMenu', topNavMenu)



// axios 发起网络请求
import axios from 'axios';
// axios.defaults.baseURL = 'https://hunlian.spg.zhongwangyingtong.com'; // 服务器域名
axios.defaults.baseURL = 'https://www.aisiman.cn/index.php'; // 服务器域名
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';


// const baseURL = 'https://hunlian.spg.zhongwangyingtong.com';
const baseURL = 'https://www.aisiman.cn/index.php';
// 上传图片的地址
Vue.prototype.$uploadImageUrl = `${baseURL}/api/upload/image`;
// 上传视频的地址
Vue.prototype.$uploadVideoUrl = `${baseURL}/manage/Customer/video`;
// 上传文件地址
Vue.prototype.$importFile = `${baseURL}/api/user/import_user`;


// 响应拦截
axios.interceptors.response.use(function(response) {

	return response.data;
}, function(error) {
	
	return Promise.reject(error);
});

Vue.prototype.$axios = axios;



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
