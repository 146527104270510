<template>
	<!-- 顶部导航菜单 -->
	<div class="container">

		<div class="top-nav-container">
			<!-- 左边  -->
			<div class="nav-left">
				<div class="logo">
					<img src="../assets/images/index-logo.png" alt="">
				</div>
				<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" background-color="#1C212E"
					text-color="#fff" :router="true" active-text-color="#409eff">
					<el-menu-item index="dashboard">首页</el-menu-item>
					<el-submenu :index="String(item.id)" v-for="(item, index) in dynamicRouting_list" :key="item.id">
						<template slot="title">{{ item.label }}</template>
						<el-menu-item :index="cell.path" v-for="cell in item.children" :key="cell.id">{{ cell.label
						}}</el-menu-item>
					</el-submenu>

					<!-- <el-submenu index="2">
						<template slot="title">资源分配</template>
						<el-menu-item index="resourcequery">已分配查询</el-menu-item>
					</el-submenu>
					<el-submenu index="3">
						<template slot="title">客户管理</template>
						<el-menu-item index="customerappointment">客户预约</el-menu-item>
						<el-menu-item index="salestoallocate">销售待分配</el-menu-item>
						<el-menu-item index="salesCustomer">销售客户</el-menu-item>
						<el-menu-item index="serviceDivide">服务待分配</el-menu-item>
						<el-menu-item index="serviceCustomer">服务客户</el-menu-item>
						<el-menu-item index="serviceEndCustomer">服务完结客户</el-menu-item>
						<el-menu-item index="talented">佳丽才俊</el-menu-item>
						<el-menu-item index="seaCustomer">公海客户</el-menu-item>
						<el-menu-item index="virtualLibrary">我的虚拟库</el-menu-item>
					</el-submenu>
					<el-submenu index="4">
						<template slot="title">运营管理</template>
						<el-menu-item index="callLog">通话记录</el-menu-item>
						<el-menu-item index="messageRecord">短信记录</el-menu-item>
					</el-submenu>
					<el-submenu index="5">
						<template slot="title">订单管理</template>
						<el-menu-item index="orderHandle">订单处理</el-menu-item>
						<el-menu-item index="refundHandle">退款处理</el-menu-item>
					</el-submenu>
					<el-submenu index="6">
						<template slot="title">数据统计</template>
						<el-menu-item index="callData">通话相关数据</el-menu-item>
						<el-menu-item index="operateData">运营数据</el-menu-item>
					</el-submenu>
					<el-submenu index="8">
						<template slot="title">系统设置</template>
						<el-menu-item index="accountMan">账号管理</el-menu-item>
						<el-menu-item index="organizationMan">组织管理</el-menu-item>
					</el-submenu> -->

				</el-menu>
			</div>

			<!-- 右边 -->
			<div class="nav-right">
				<div class="search">
					<el-input prefix-icon="el-icon-search" placeholder="请输入搜索内容" clearable v-model="top_keyword"
						@change="changeKeyword" @input="inputKeyword">
					</el-input>
				</div>
				<div class="msg" @click="routeJump">
					<el-badge is-dot class="item">
						<i class="el-icon-bell"></i>
					</el-badge>
				</div>
				<div class="avatar-container">
					<el-dropdown trigger="click" @command="handleCommand">
						<span class="el-dropdown-link userInfo">
							<el-avatar size="medium" icon="el-icon-user-solid"></el-avatar>
							<div class="name">{{ user_info.real_name }}</div>
							<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="userInfo">个人中心</el-dropdown-item>
							<el-dropdown-item command="logout">退出</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
		</div>
		<!-- 左边弹出层 -->
		<el-drawer title="个人信息" :visible.sync="drawer" :modal="false">
			<div class="user-info-container">
				<div class="info">
					<div class="left">
						<el-avatar size="medium" icon="el-icon-user-solid"></el-avatar>
					</div>
					<div class="right">
						<div class="name">姓名: {{ user_info.real_name }}</div>
						<!-- <div class="name">角色: 门店店长</div>
						<div class="name">组织: 北京东直门店Q</div> -->
					</div>
				</div>
				<!-- <div class="phone">
					<div class="title fon14">手机号</div>
					<el-input placeholder="请输入手机号" maxlength="11" :show-word-limit="true" clearable>
					</el-input>
				</div> -->
				<div class="phone">
					<div class="title fon14">账号</div>
					<div class="r">
						<span class="fon14">{{ user_info.user_name }}</span>
					</div>
				</div>
				<div class="phone">
					<div class="title fon14">密码</div>
					<div class="r">
						<el-button @click="isShowPassword = true" size="small" type="primary">修改密码</el-button>
					</div>
				</div>

				<!-- <div class="save-btn">
					<el-button size="small" type="primary">保存</el-button>
				</div> -->

			</div>
		</el-drawer>


		<!-- 修改密码 -->
		<el-dialog title="修改密码" class="pass-container" :visible.sync="isShowPassword" :modal="false" width="25%">
			<el-form ref="passwordFormRef" :model="passwordForm" :rules="passwordFormRules">
				<el-form-item label="密码" label-width="120px" prop="password">
					<el-input show-password v-model="passwordForm.password" clearable placeholder="请输入修改密码"
						autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="确定密码" label-width="120px" prop="confirm_password">
					<el-input show-password v-model="passwordForm.confirm_password" clearable placeholder="请再次输入修改密码"
						autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="isShowPassword = false">取 消</el-button>
				<el-button type="primary" @click="updatePassword">确 定</el-button>
			</div>
		</el-dialog>



		<!-- 导航标签 -->
		<!-- <div class="tags-container">
			<el-tag :type="index == nav_active ? '' : 'info'" :effect="index == nav_active ? 'dark' : 'plain'"
				v-for="(tag,index) in nav_tags" :key="tag.path" :closable="index != 0" @close="closeTag(tag)"
				@click="clickTag(tag)">
				{{tag.title}}
			</el-tag>
		</div> -->
		<tags-nav></tags-nav>

		<div class="sign-fixed" v-if="user_id != 9" @click="isShow = true">
			<i class="el-icon-edit-outline"></i>
			<span>到店签到</span>
		</div>

		<!-- 审核订单 -->
		<el-dialog title="签到" width="30%" :modal="false" :visible.sync="isShow">
			<div class="sign-container">
				<div class="mobile-wrap">
					<!-- <el-input v-model="mobile" @change="searchUser" placeholder="请输入手机号查询"></el-input> -->
					<el-input v-model="mobile" placeholder="请输入手机号查询"></el-input>
					<el-button type="primary" @click="searchUser" size="small" icon="el-icon-search">搜索</el-button>
				</div>
				<div class="list" v-if="signUser != null" @click="gode">
					<div class="avtar-url">
						<el-avatar shape="square" :src="signUser.avatarUrl"></el-avatar>
					</div>
					<div class="name">
						{{ signUser.nickName }}
					</div>
					<div class="name">
						{{ signUser.mobile }}
					</div>
				</div>

				<div slot="footer" class="dialog-footer" style="text-align: right; margin-top: 30px;">
					<el-button @click="isShow = false">关闭</el-button>
					<el-button type="primary" @click="signSubmit">已到店</el-button>
				</div>
			</div>
		</el-dialog>

		<!-- 弹出框 -->
		<div class="pop-up" v-show="ispop == true">
			<div class="content">
				<div class="title">
					<div class="left">
						查询结果
					</div>
					<div class="right" @click="ispop = false">
						<i class="el-icon-close"></i>
					</div>
				</div>
				<div class="tab">
					<div class="tabbox">
						<div class="item" :class="{ active: tabindex == i }" v-for="(item, i) in tops_list"
							@click="changtabindex(i)">
							结果{{ i }}
						</div>
					</div>
				</div>
				<div class="main">
					<div class="left">
						<div class="it">昵称：{{ detail.nickName }}</div>
						<div class="it">年龄：{{ detail.age }}</div>
						<div class="it" v-if="detail.education == 0">学历：未填写</div>
						<div class="it" v-if="detail.education == 1">学历：高中以下</div>
						<div class="it" v-if="detail.education == 2">学历：大专</div>
						<div class="it" v-if="detail.education == 3">学历：本科</div>
						<div class="it" v-if="detail.education == 4">学历：研究</div>
						<div class="it" v-if="detail.education == 5">学历：博士</div>
						<div class="it">年收入：{{ detail.annual_income }}</div>
					</div>
					<div class="right">
						<div class="it">客户UID：{{ detail.user_id }}</div>
						<div class="it">性别：{{ detail.nickName }}</div>
						<div class="it">所属地：{{ detail.location_place }}</div>
						<div class="it" v-if="detail.marital_status == 0">婚姻状况：未婚</div>
						<div class="it" v-if="detail.marital_status == 10">婚姻状况：丧偶</div>
						<div class="it" v-if="detail.marital_status == 30">婚姻状况：离异</div>
					</div>
				</div>
				<div class="bot">
					<div class="a"></div>
					<div class="btn" @click="godetail">查看详情</div>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
import {
	mapState
} from 'vuex';
import {
	mapMutations,
	mapGetters
} from 'vuex';

// import dynamicRouting_list from '@/assets/js/dynamicRouting'

// import dynamicRouting_list from '@/assets/json/dynamicRouting.json'

import tagsNav from '@/components/common/tags-nav.vue';


import { getCurrentInstance } from 'vue';
 
export default {
	components: {
		tagsNav
	},
	data() {
		// 定义 密码校验规则
		var validatePass = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入密码'));
			} else {
				if (this.passwordForm.confirm_password !== '') {
					this.$refs.passwordFormRef.validateField('confirm_password');
				}
				callback();
			}
		};
		var validatePassConfirm = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.passwordForm.password) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};

		return {

			// dynamicRouting_list: dynamicRouting_list, // 动态路由
			dynamicRouting_list: [], // 动态路由
			select_Routing: [1, 101, 201, 202], // 选中的路由


			tabindex: 0,
			drawer: false, // 个人信息
			nav_tags: this.$store.state.routerHistory, // 导航
			nav_active: 0, // 导航选中

			user_info: '', // 用户信息
			isShowPassword: false,
			ispop: false,
			detail: {},
			passwordForm: {
				password: '', // 密码
				confirm_password: '', // 确定密码
				token: this.$store.state.token,
			},
			passwordFormRules: {
				password: [{
					required: true,
					message: '请输入密码',
					trigger: 'blur'
				},
				{
					min: 6,
					max: 15,
					message: '长度在 6 到 15 个字符',
					trigger: 'blur'
				},
				{
					validator: validatePass,
					trigger: 'blur'
				}
				],
				confirm_password: [{
					required: true,
					message: '请再次输入修改密码',
					trigger: 'blur'
				},
				{
					min: 6,
					max: 15,
					message: '长度在 6 到 15 个字符',
					trigger: 'blur'
				},
				{
					validator: validatePassConfirm,
					trigger: 'blur',
					required: true
				}
				]
			},


			isShow: false,
			mobile: '',
			signUser: null, // 签到用户
			tops_list: [],
			user_id: '',
			top_keyword: '',
			nowRouter:''
		}
	},
	props: {
		// 选中导航栏
		activeIndex: {
			type: String,
			default: 'dashboard'
		},
	},
	created() {
		this.user_id = sessionStorage.getItem('user_id'),

			this.getUserInfo()
		this.getDynamicRouting() // 获取动态路由
		
	},
	computed: {
		...mapGetters(['routerHistory'])
	},
	activated() {

		this.getDynamicRouting()
	},
	methods: {
		...mapMutations(['saveTokenStorage', 'removeToken', 'setRoleId']),

		godetail() {
			this.ispop = false

			this.$router.replace({
				name: 'customerDetail',
				query: {
					user_id: this.detail.user_id
				}
			})
 
			this.nowRouter = this.$store.state.app.nowRouter;
			console.log(this.nowRouter, '888888888888888')
			if(this.nowRouter =='/customerDetail'){
				this.$router.go(0)
			}else{
				
			}
			// console.log(this.$router,'this.$router');
			// this.$router.go(0)
		},
		gode(){
			console.log('aaa')
			this.$router.replace({
				name: 'customerDetail',
				query: {
					user_id: this.signUser.user_id
				}
			})
			this.nowRouter = this.$store.state.app.nowRouter;
			console.log(this.nowRouter, '888888888888888')
			if(this.nowRouter =='/customerDetail'){
				this.$router.go(0)
			}else{
				
			}
		},
		changtabindex(i) {
			this.tabindex = i
			this.detail = this.tops_list[i]
		},
		changeKeyword() {
			if (this.top_keyword == '') {
				this.detail={}
			}else{
				this.ispop = true
			this.getSSlist()
			}
			 

		},
		inputKeyword(){
			if (this.top_keyword == '') {
				this.detail={}
			}else{
				this.detail={}
			this.getSSlist()
			}
		},
		// 下拉菜单
		handleCommand(e) {
			let that = this

			if (e === 'userInfo') {
				console.log('个人中心')
				this.drawer = true
			}
			if (e === 'logout') {
				console.log('退出')
				// window.sessionStorage.removeItem('token');
				// window.sessionStorage.removeItem('user_id');

				this.removeToken()
				this.saveTokenStorage()
				this.$store.commit('cleanRouterHistory')

				this.$store.commit('setRouterHistory', [{
			title: "首页",
			path: "/dashboard"
		}])

				this.$message.success('退出成功')
				setTimeout(() => {
					that.$router.push('/')
				}, 1000)

			}

		},

		routeJump() {
			this.$router.push('/messageCenter')
		},
		//获取搜索列表
		getSSlist() {
			
		
				this.$axios.get('/manage/Customer/find_user', {
					params: {
						token: this.$store.state.token,
						str: this.top_keyword,
					}
				}).then(res => {
					console.log('搜索列表', res)
					if (res.code == 1) {
						this.tops_list = res.data.user_list
						if (res.data.user_list.length > 0) {
							this.detail = res.data.user_list[0]
						}

					}

				});
			


		},
		// 获取用户信息
		getUserInfo() {
			this.$axios.get('/manage/customer/detail', {
				params: {
					token: this.$store.state.token,
				}
			}).then(res => {
				console.log('用户信息', res)
				if (res.code == 1) {
					this.user_info = res.data.userInfo
					this.setRoleId(res.data.userInfo.role_id)
					this.saveTokenStorage()
				}

			});

		},


		// 修改密码
		updatePassword() {
			let that = this;

			this.$refs.passwordFormRef.validate(valid => {
				if (!valid) return;

				this.$axios.post('/api/Customer/editPassword', this.passwordForm).then(res => {
					if (res.code == 1) {
						this.$message.success('修改成功');

						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('user_id')


						setTimeout(() => {
							that.$router.push('/login')
						}, 2000)

					} else {
						this.$message.error(res.msg)
					}

				});


			})

		},


		// 搜索
		searchUser() {
			console.log(123);
			this.signUser = null;

			if (!this.mobile) {
				this.$message.error('请输入手机号')
				return
			}


			this.$axios.get('/manage/Customer/select_user', {
				params: {
					mobile: this.mobile,
					token: this.$store.state.token,
				}
			}).then(res => {
				if (res.code == 1) {
					this.signUser = res.data;

				} else {
					this.$message.error(res.msg)
				}

			});


		},


		signSubmit() {

			if (this.signUser == null) {
				this.$message.error('请先搜索用户')
				return
			}

			this.$axios.post('/manage/Customer/set_user_come', {
				user_id: this.signUser.user_id,
				token: this.$store.state.token,
			}).then(res => {
				if (res.code == 1) {
					this.$message.success('签到成功')
					this.isShow = false;
					this.mobile = '';
					this.signUser = null;
				} else {
					this.$message.error(res.msg)
				}

			});

		},


		// 获取动态路由
		getDynamicRouting() {
			// console.log('456', this.dynamicRouting_list);

			this.$axios.get('/manage/Customer/get_user_power_authority', {
				params: {
					token: this.$store.state.token,
				}
			}).then(res => {
				console.log('权限配置', res)
				if (res.code == 1) {
					console.log(JSON.parse(res.data));
					this.dynamicRouting_list = JSON.parse(res.data)
				} else {
					this.$message.error(res.msg)
				}

			});

		},



	}
}
</script>

<style lang="less" scoped>
.pop-up {
	position: absolute;
	z-index: 999;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: #5f5f5f54;
	display: flex;
	align-items: center;
	justify-content: center;

	.content {
		width: 600px;
		height: 360px;
		background-color: #fff;
		box-sizing: border-box;
		padding: 20px;

		.title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: #000 !important;
			padding-bottom: 20px;
			border-bottom: 1px solid #ebebeb;

			.right {
				color: #000;

				/deep/ i {
					color: #000;
				}
			}
		}

		.tab {
			width: 100%;
			height: 70px;
			overflow-x: auto;
			// overflow-y: hidden;

			.tabbox {
				overflow-x: auto;
				// overflow-y: hidden;
				display: flex;
				flex-wrap: nowrap;

			}

			.item {
				flex-shrink: 0;
				width: 85px;
				padding: 10px;
				height: 40px;
				// padding-bottom: 20px;
				box-sizing: border-box;
				border-bottom: 1px solid #40a0ff00;
				cursor: pointer;

				// display: inline-block;
				&.active {
					color: #409eff;
					// border-bottom: 1px solid #409eff;
				}
			}
		}

		.main {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-bottom: 20px;
			border-bottom: 1px solid #ebebeb;

			.left,
			.right {
				width: 50%;
				font-size: 15px;
				color: #1d1c1c;

				.it {
					margin-top: 10px;
				}
			}
		}

		.bot {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.btn {
				width: 100px;
				height: 40px;
				color: #fff;
				border-radius: 6px;
				background-color: #409eff;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 20px;
				cursor: pointer;
			}
		}
	}
}

.sign-container {

	.list {
		display: flex;
		align-items: center;
		padding: 20px 0;

		.avtar-url {}

		.name {
			font-size: 14px;
			margin-left: 40px;
		}
	}
}


.container {
	position: sticky;
	top: 0;
	z-index: 999;
	min-width: 1440px;
}

.top-nav-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 50px;
	padding: 0 30px;
	background-color: #1C212E;

	.logo {
		margin-right: 30px;
		height: 34px;

		img {
			height: 100%;
		}
	}

	.nav-left {
		display: flex;
		align-items: center;
	}

	.nav-right {
		display: flex;
		align-items: center;

		.msg {
			margin: 0 12px;
			cursor: pointer;

			:deep .el-badge__content {
				border: none;
			}
		}
	}
}

:deep .el-menu--horizontal>.el-menu-item {
	padding: 0 15px;
	height: 50px;
	line-height: 50px;
}

:deep .el-menu.el-menu--horizontal {
	height: 50px;
	border-bottom: none;
}

:deep .el-menu--horizontal>.el-submenu .el-submenu__title {
	padding: 0 15px;
	height: 50px;
	line-height: 50px;
}

:deep .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
	background: #333A4A !important;
}

:deep [class^=el-icon-] {
	font-size: 20px;
	color: #fff;
}

.mobile-wrap {
	display: flex;

}


.mobile-wrap :deep [class^=el-icon-] {
	font-size: 14px;
	color: #fff;
}


.avatar-container {
	display: flex;
	align-items: center;
	margin-top: 2px;
	cursor: pointer;

	.userInfo {
		display: flex;
		align-items: center;
	}

	.name {
		margin: 0 3px;
		max-width: 60px;
		font-size: 15px;
		color: #fff;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

:deep .el-drawer__container {
	background: rgba(0, 0, 0, .3);
}

.tags-container {
	padding: 10px 30px;
	background: #fff;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);

	:deep .el-tag {
		margin-right: 10px;
	}
}

.search {
	:deep .el-input__inner {
		font-size: 14px;
		height: 35px;
	}

	:deep .el-input__icon {
		line-height: 35px;
	}
}

.user-info-container {
	padding: 0 20px;

	.info {
		display: flex;
		align-items: center;

		.left {
			:deep .el-avatar--medium {
				width: 80px;
				height: 80px;
				line-height: 100px;
			}

			:deep [class^=el-icon-] {
				font-size: 50px;
				color: #fff;
			}

		}

		.right {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			margin-left: 20px;
			font-size: 15px;
			color: #72767b;
			height: 75px;
		}
	}

	.phone {
		display: flex;
		align-items: center;
		margin: 20px 0;
		width: 100%;

		.title {
			width: 70px;
			color: #72767b;
			text-align: right;
		}

		:deep .el-input {
			flex: 1;
			margin-left: 20px;
		}

		.r {
			margin-left: 20px;
		}

	}

	.save-btn {
		width: 100%;
		margin-top: 20px;

		:deep .el-button {
			width: 100%;
		}
	}

}


.pass-container {
	:deep .el-form-item__label::before {
		content: '*';
		color: red;
	}
}


.sign-fixed {
	position: fixed;
	right: 0;
	bottom: 100px;
	width: 80px;
	height: 80px;
	padding: 10px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: var(--baseColor);
	color: #fff;
	font-size: 26px;
	cursor: pointer;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
	border-radius: 6px 0 0 6px;

	span {
		margin-top: 6px;
		font-size: 16px;
	}
}
</style>

<style>
.el-menu--collapse .el-menu .el-submenu,
.el-submenu__title,
.el-menu--popup {
	min-width: 110px !important;
}

.el-drawer__wrapper .el-drawer__container .el-drawer.rtl {
	width: 25% !important;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
	background: #333A4A !important;
}

.el-tag.el-tag--info {
	background-color: #fff !important;
}
</style>